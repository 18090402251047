import { Flex, HStack, Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

function SkeletonForm() {
  return (
    <VStack spacing={4} p={6} pt={0} align="start">
      <Skeleton height={7} width={20} />
      <Flex wrap="wrap" justifyContent="space-between" gap={2} width="100%">
        <Skeleton height={12} width="32%" />
        <Skeleton height={12} width="32%" />
        <Skeleton height={12} width="32%" />
        <Skeleton height={12} width="32%" />
        <Skeleton height={12} width="32%" />
        <Skeleton height={12} width="32%" />
      </Flex>
      {/* Notes */}
      <Skeleton height={7} width={20} />
      <Skeleton height={20} width="full" />
      {/* Completion */}
      <HStack width="full">
        <Skeleton height={12} width="10%" />
        <Skeleton height={4} width="90%" />
      </HStack>
      {/* Moral */}
      <HStack width="full">
        <Skeleton height={12} width="10%" />
        <Skeleton height={4} width="90%" />
      </HStack>
      {/* Experience School */}
      <HStack width="full">
        <Skeleton height={12} width="10%" />
        <Skeleton height={4} width="90%" />
      </HStack>
      <HStack width="full">
        <Skeleton height={12} width="10%" />
        <Skeleton height={4} width="90%" />
      </HStack>
    </VStack>
  );
}

export default SkeletonForm;
