import { HStack, Icon, IconButton, NumberInput, NumberInputField } from '@chakra-ui/react';
import { addMinutes, differenceInMinutes } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash } from 'react-icons/fi';

import { DatePicker } from '@/components/ui-elements/DatePicker';

type SlotBreakInput = {
  idx: number;
  remove: (idx: number) => void;
  minHour: Date;
  maxHour: Date;
  setBreakValue: (idx: number, value: Date) => void;
  startDate?: Date | null;
  endDate?: Date | null;
};

function _SlotBreakInput({
  idx,
  remove,
  minHour,
  maxHour,
  setBreakValue,
  startDate,
  endDate,
}: SlotBreakInput) {
  const onBreakValueChange = React.useCallback(
    (value: string) => {
      if (!value || value === '') {
        value = '0';
      }
      if (startDate) {
        setBreakValue(idx, addMinutes(startDate, parseInt(value, 10)));
      }
    },
    [idx, setBreakValue, startDate],
  );

  const { t } = useTranslation('layouts/calendar/eventForm/slots/EventFormSlots');
  const { t: tForm } = useTranslation('components/event-form');
  const { t: tShared } = useTranslation('components/shared');

  const breakDuration = React.useMemo(() => {
    if (startDate && endDate) {
      return differenceInMinutes(endDate, startDate, { roundingMethod: 'round' });
    }
    return 0;
  }, [startDate, endDate]);

  const timeFormat = tShared('timeFormat') === 'timeFormat' ? 'hh:mm a' : tShared('timeFormat');

  return (
    <HStack w="full" justify="space-between">
      <DatePicker
        disabled={!minHour || !maxHour}
        name={`slots.breaks.${idx}.start`}
        size="sm"
        showTimeSelect
        showTimeSelectOnly
        timeCaption="Time"
        dateFormat={timeFormat}
        defaultValue={null}
        placeholderText={tForm('begin')}
        timeIntervals={5}
        timeDate={minHour}
        minTime={minHour}
        maxTime={endDate || maxHour}
      />
      <DatePicker
        disabled={!minHour || !maxHour}
        name={`slots.breaks.${idx}.end`}
        size="sm"
        showTimeSelect
        showTimeSelectOnly
        timeCaption="Time"
        dateFormat={timeFormat}
        defaultValue={null}
        placeholderText={tForm('end')}
        timeIntervals={5}
        timeDate={minHour}
        minTime={startDate || minHour}
        maxTime={maxHour}
      />
      <NumberInput
        isDisabled={!minHour || !maxHour}
        variant="white"
        size="sm"
        value={breakDuration != 0 ? breakDuration : undefined}
        onChange={onBreakValueChange}
        min={0}
      >
        <NumberInputField placeholder={t('duration')} />
      </NumberInput>
      <IconButton
        isDisabled={!minHour || !maxHour}
        aria-label={t('remove')}
        icon={<Icon as={FiTrash} />}
        size="xs"
        variant="ghost"
        colorScheme="red"
        onClick={() => remove(idx)}
      />
    </HStack>
  );
}

// INFO: disabled is bugged in chakraUI for IconButton and NumberInput using isDisabled instead

export const SlotBreakInput = React.memo(_SlotBreakInput);
