import {
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiClock, FiEdit2, FiPlus, FiX } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '@/config/environment';
import {
  useRegisterToEventMutation,
  useUnregisterFromEventMutation,
} from '@/store/services/events';

import { useEventDetailsContext } from '../detailsProvider/useEventDetailsContext';
import { EventDelete } from '../EventDelete';
import { EventRegisterAsWrapper } from '../RegisterAsWrapper';
import { EventDetailsHistory } from './History';

function _EventActionButtons() {
  const {
    eventId,
    isAlreadyRegistered,
    toRegister,
    isGroupMode,
    type,
    canUpdate,
    canDelete,
    canViewHistory,
    canRegister,
  } = useEventDetailsContext();
  const { onClose } = useModalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [registerToEvent, { isLoading: isRegisterLoading }] = useRegisterToEventMutation();
  const [unregisterFromEvent, { isLoading: isUnregisterLoading }] =
    useUnregisterFromEventMutation();
  const { t } = useTranslation('components/events');

  const onEventEdit = useCallback(() => {
    navigate({
      pathname: `/calendar/events/${eventId}/edit`,
      search: location.search,
    });
  }, [eventId, navigate, location.search]);

  const onEventDelete = useCallback(async () => {
    toast({
      description: t('delete_success'),
      status: 'success',
      position: 'top',
    });
    onClose();
  }, [onClose, toast, t]);

  const onEventRegister = useCallback(async () => {
    if (!toRegister) {
      return;
    }
    /** Here we can be sure that toRegister will be defined since its used only after a check (@link getActionButtons} */
    await registerToEvent({
      eventId,
      toRegister,
      type: isGroupMode ? 'projectGroups' : 'users',
    }).unwrap();
    toast({
      description: t('inscription_success'),
      status: 'success',
      position: 'top',
    });
  }, [registerToEvent, toRegister, eventId, toast, t, isGroupMode]);

  const onEventUnregister = useCallback(async () => {
    if (!toRegister) {
      return;
    }
    /** Here we can be sure that toRegister will be defined since its used only after a check (@link getActionButtons} */
    await unregisterFromEvent({
      eventId,
      type: isGroupMode ? 'projectGroups' : 'users',
      toRegister: toRegister,
    }).unwrap();
    toast({
      description: t('unsubscribe_success'),
      status: 'success',
      position: 'top',
    });
  }, [toRegister, eventId, isGroupMode, toast, unregisterFromEvent, t]);

  const getActionButton = useMemo(() => {
    if (toRegister?._id) {
      if (type === 'course') {
        if (isAlreadyRegistered) {
          return (
            <Button
              mr="auto"
              onClick={onEventUnregister}
              isLoading={isUnregisterLoading}
              isDisabled={!canRegister}
            >
              {t('unsubscribe')}
            </Button>
          );
        }
        return (
          <EventRegisterAsWrapper isGroupMode={isGroupMode} toRegister={toRegister}>
            <Button
              rightIcon={<Icon as={FiPlus} />}
              mr="auto"
              isDisabled={!canRegister}
              isLoading={isRegisterLoading}
              onClick={onEventRegister}
            >
              {t('subscribe')}
            </Button>
          </EventRegisterAsWrapper>
        );
      }
    }
    return null;
  }, [
    isGroupMode,
    canRegister,
    type,
    toRegister,
    isAlreadyRegistered,
    onEventRegister,
    onEventUnregister,
    isUnregisterLoading,
    isRegisterLoading,
    t,
  ]);

  return (
    <ButtonGroup isAttached colorScheme="blue" size="sm">
      {getActionButton}
      {canViewHistory && (
        <Popover isLazy={true} size="lg" computePositionOnMount={true}>
          <PopoverTrigger>
            <IconButton aria-label="history" icon={<FiClock />} />
          </PopoverTrigger>
          <PopoverContent fontSize="md" minW="sm" w="fit-content">
            <PopoverArrow />
            <PopoverCloseButton top={3} />
            <EventDetailsHistory />
          </PopoverContent>
        </Popover>
      )}
      {canUpdate && (
        <IconButton aria-label={t('edit')} icon={<Icon as={FiEdit2} />} onClick={onEventEdit} />
      )}
      {canDelete && <EventDelete onConfirm={onEventDelete} />}
      <IconButton icon={<Icon as={FiX} />} aria-label={t('close')} onClick={onClose} />
    </ButtonGroup>
  );
}

export const EventActionButtons = React.memo(_EventActionButtons);

if (environment.__DEV__) {
  EventActionButtons.displayName = 'EventActionButtons';
}
