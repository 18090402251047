import { Alert, AlertIcon, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IUnregisteredByEdit } from '@/store/services/events';

import { CoursePreviewList } from './PreviewCourse';

interface IEventCourseWarningProps {
  unregisteredByEdit: IUnregisteredByEdit;
}

function _EventCourseWarning({ unregisteredByEdit }: IEventCourseWarningProps) {
  const { t } = useTranslation('components/warnings');
  return (
    <VStack spacing={2} boxShadow="inner">
      <Alert status="warning" variant="subtle" borderTopRadius="md">
        <AlertIcon />
        {t(`edit_event_type_warning`)}.
      </Alert>
      <CoursePreviewList
        registered={
          unregisteredByEdit?.registeredUsers
            ? unregisteredByEdit.registeredUsers
            : unregisteredByEdit.registeredGroups
        }
        registeredStaffs={unregisteredByEdit.registeredStaffs}
        isGroupMode={unregisteredByEdit?.registeredUsers ? false : true}
      />
    </VStack>
  );
}

export const EventCourseWarning = React.memo(_EventCourseWarning);
