import { StoredEventEntry } from '@epitech/ops-panoramix-events-types';
import { useMemo } from 'react';

import { useGetEventQuery } from '@/store/services/events';

import { sanitizeEventPayload } from '../helpers/events.helpers';

export function useGetEventSanitizedQuery<
  E extends StoredEventEntry | null = StoredEventEntry | null,
>(eventId: string, options?: Parameters<typeof useGetEventQuery>[1]) {
  // fixme: options change return type (have to add as unknown as undefined)
  const queryResult = useGetEventQuery(eventId, options as unknown as undefined);

  const event = useMemo(() => sanitizeEventPayload(queryResult.data), [queryResult.data]);

  return { ...queryResult, data: event as E };
}
