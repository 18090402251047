import { HStack, Tag, Text } from '@chakra-ui/react';
import {
  chakraComponents,
  GroupBase,
  OptionProps,
  SelectComponentsConfig,
} from 'chakra-react-select';
import { decode } from 'he';
import React from 'react';

import type { IRoomOption } from './helpers';

export const RoomOptionMultiValue: SelectComponentsConfig<
  IRoomOption,
  true,
  GroupBase<IRoomOption>
>['Option'] = React.memo(({ children, ...props }) => (
  <chakraComponents.Option key={props.data.value._id} {...props}>
    {children}
    <Tag ml="auto" overflow="hidden" variant="moduleCode">
      {decode(props.data.value.city)}
    </Tag>
  </chakraComponents.Option>
));

export function RoomOption<IsMulti extends boolean = false>({
  children,
  ...props
}: React.PropsWithChildren<OptionProps<IRoomOption, IsMulti>>) {
  const { data } = props;

  return (
    <chakraComponents.Option key={props.data.value._id} {...props}>
      <HStack alignItems="center" justifyContent="space-between" w="full" gap={2}>
        <Text flex={1} overflow="hidden" textOverflow="ellipsis">
          {children}
        </Text>
        <Tag variant="moduleCode" px={2} flexShrink={0}>
          {decode(data.value.city)}
        </Tag>
      </HStack>
    </chakraComponents.Option>
  );
}
