import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import type { EventCourseEntry } from '@epitech/ops-panoramix-events-types';
import { Stored } from '@epitech/ops-panoramix-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchMenuMulti } from '@/components/ui-elements/SearchMenu/Multi';
import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { IUserSearchForm, UserSearchForm } from '@/components/users/SearchForm';
import { type IUserOption, UserOption, useUserSearch } from '@/components/users/select';
import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';
import {
  useForceRegisterToEventMutation,
  useForceRegisterUsersWithMetadataToEventMutation,
} from '@/store/services/events';

export type ForceRegisterCourseUsersProps = {
  event: Stored<EventCourseEntry>;
  onClose: () => void;
};

function _ForceRegisterCourseUsers({ event, onClose }: ForceRegisterCourseUsersProps) {
  const searchUsers = useUserSearch({
    moduleRef: event.moduleRef,
    cohortGroups: event.cohortGroups,
  });

  const [forceRegisterToEvent, { isLoading: isForceRegisterLoading }] =
    useForceRegisterToEventMutation();
  const [forceRegisterToEventFromMetadata, { isLoading: isForceRegisterFromMetadataLoading }] =
    useForceRegisterUsersWithMetadataToEventMutation();
  const { t } = useTranslation('components/events');
  const isRegisterLoading = isForceRegisterLoading || isForceRegisterFromMetadataLoading;

  const onSubmitHandler = useCallback(
    (selectedOptions: IUserOption[]) => {
      const selectedUsersRefs = selectedOptions.map(opt => opt.value);
      forceRegisterToEvent({ eventId: event._id!, toRegisters: selectedUsersRefs, type: 'users' });
      onClose();
    },
    [onClose, event._id, forceRegisterToEvent],
  );

  const onSubmitFromMetadata = useCallback(
    ({ cohortGroups, moduleRef }: IUserSearchForm) => {
      forceRegisterToEventFromMetadata({ eventId: event._id!, cohortGroups, moduleRef });
      onClose();
    },
    [event._id, forceRegisterToEventFromMetadata, onClose],
  );

  const filterOption = useCallback(
    (option: FilterOptionOption<IUserOption>) => {
      return !event?.registeredUsers.some(
        registeredUser => registeredUser._id === option.data.value._id,
      );
    },
    [event?.registeredUsers],
  );

  const onNoOptions = useCallback(() => t('empty_user-search'), [t]);

  return (
    <Tabs isFitted={true} w="full" size="sm" isLazy={true}>
      <TabList>
        <Tab>{t('register_by_login')}</Tab>
        <Tab>{t('register_by_metadata')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SearchMenuMulti<IUserOption>
            name="forceRegisterUsers"
            loadOptions={searchUsers}
            onSubmit={onSubmitHandler}
            components={{
              Option: UserOption,
              Value: UserItem,
            }}
            size="sm"
            isLoading={isRegisterLoading}
            noOptionsMessage={onNoOptions}
            filterOption={filterOption}
            infiniteScroll={true}
          />
        </TabPanel>
        <TabPanel>
          <UserSearchForm
            cohortGroups={event.cohortGroups}
            moduleRef={event.moduleRef ? event.moduleRef : undefined}
            onSubmit={onSubmitFromMetadata}
            isSubmitLoading={isRegisterLoading}
            submitBtnLabel={t('register')}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export const ForceRegisterCourseUsers = React.memo(_ForceRegisterCourseUsers);

if (environment.__DEV__) {
  ForceRegisterCourseUsers.displayName = 'ForceRegisterCourseUsers';
}
