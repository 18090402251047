import {
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { EventCourseEntry } from '@epitech/ops-panoramix-events-types';
import type { Stored } from '@epitech/ops-panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserX } from 'react-icons/fi';

import { PopoverPortal } from '@/components/ui-elements/PopoverPortal';
import { environment } from '@/config/environment';
import { useGetEventSanitizedQuery } from '@/lib/hooks/useGetEventSanitizedQuery';

import { EventForceUnregisterUsersFromMetadataActionButtonContent } from './Content';

type ForceUnregisterUsersActionButton = {
  eventId: string;
  hasPortal?: boolean;
};

function _ForceUnregisterUsersFromMetadataActionButton({
  eventId,
  hasPortal = true,
  ...buttonProps
}: ForceUnregisterUsersActionButton & Partial<IconButtonProps>) {
  const { t } = useTranslation('components/events');
  const { data: event } = useGetEventSanitizedQuery<Stored<EventCourseEntry>>(eventId);

  if (!event || event.type !== 'course') {
    throw new Error('Only course events can unregister users');
  }

  const Wrapper = hasPortal ? PopoverPortal : React.Fragment;

  return (
    <Popover
      closeOnBlur={true}
      placement="auto"
      isLazy={true}
      lazyBehavior="unmount"
      boundary="scrollParent"
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <IconButton
          isDisabled={!event || event.registeredUsers.length === 0}
          icon={<FiUserX />}
          aria-label={t('unregister')}
          size="xs"
          onClick={e => e.stopPropagation()}
          {...buttonProps}
        />
      </PopoverTrigger>
      <Wrapper>
        <PopoverContent p={4} boxShadow="lg" minW="xs" w="fit" onClick={e => e.stopPropagation()}>
          <PopoverArrow />
          <EventForceUnregisterUsersFromMetadataActionButtonContent event={event} />
        </PopoverContent>
      </Wrapper>
    </Popover>
  );
}

export const EventForceUnregisterUsersFromMetadataActionButton = React.memo(
  _ForceUnregisterUsersFromMetadataActionButton,
);

if (environment.__DEV__) {
  EventForceUnregisterUsersFromMetadataActionButton.displayName =
    'EventForceUnregisterUsersFromMetadataActionButton';
}
