import { Button, Hide, HStack, Icon, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';

import { MenuPortal } from '@/components/ui-elements/MenuPortal';
import { environment } from '@/config/environment';

import { UserAvatar } from '../UserAvatar';
import { IProfileMenuContentProps, ProfileMenuContent } from './Content';

function _ProfileMenu({ user }: IProfileMenuContentProps) {
  const { t } = useTranslation('components/users');

  return (
    <Menu autoSelect={false} isLazy={true} placement="bottom-end">
      <MenuButton as={Button} variant="ghost" rightIcon={<Icon as={FiChevronDown} />}>
        <Hide below="sm">
          <HStack>
            <UserAvatar user={user} size="sm" />
            <Text>{t('profile')}</Text>
          </HStack>
        </Hide>
      </MenuButton>
      <MenuPortal>
        <MenuList>
          <ProfileMenuContent user={user} />
        </MenuList>
      </MenuPortal>
    </Menu>
  );
}

export const ProfileMenu = React.memo(_ProfileMenu);

if (environment.__DEV__) {
  ProfileMenu.displayName = 'ProfileMenu';
}
