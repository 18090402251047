import { Text } from '@chakra-ui/react';
import { formatDate } from '@fullcalendar/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/config/environment';

type EventDetailsDateProps = {
  start: Date;
  end: Date;
};

function _EventDetailsDate({ start, end }: EventDetailsDateProps) {
  const { t } = useTranslation('components/events');
  const isSameDay = start.getDate() === end.getDate();

  if (isSameDay) {
    return (
      <Text fontFamily="mono">
        {formatDate(start, {
          locale: t('language'),
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
        &nbsp; &nbsp;
        {formatDate(start, {
          locale: t('language'),
          hour: 'numeric',
          minute: 'numeric',
        })}{' '}
        {t('date_junction_short')}{' '}
        {formatDate(end, {
          locale: t('language'),
          hour: 'numeric',
          minute: 'numeric',
        })}
      </Text>
    );
  }
  return (
    <Text fontFamily="mono">
      {formatDate(start, {
        locale: t('language'),
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}{' '}
      {t('date_junction_long')}{' '}
      {formatDate(end, {
        locale: t('language'),
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}
    </Text>
  );
}

export const EventDetailsDate = React.memo<EventDetailsDateProps>(_EventDetailsDate);

if (environment.__DEV__) {
  EventDetailsDate.displayName = 'EventDetailsDate';
}
