import { Icon, useToast } from '@chakra-ui/react';
import { EventInput, IEventFormInput } from '@epitech/ops-panoramix-events-types';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import React, { useCallback } from 'react';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiEdit2 } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUpdateEventMutation } from '@/store/services/events';

import { EventForm } from './Form';
import { EventConfirmWarning } from './Warning';

type EventEditFormProps = {
  event: IEventFormInput;
};

function _EventEditForm({ event }: EventEditFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const [editEvent, { isLoading: isEditLoading, error, reset }] = useUpdateEventMutation();
  const { t } = useTranslation(['layouts/calendar', 'components/event-form']);
  const formMethods = useForm<IEventFormInput>({
    defaultValues: event as UseFormProps<IEventFormInput>['defaultValues'],
    resolver: classValidatorResolver(EventInput),
    reValidateMode: 'onSubmit',
  });

  const { getValues } = formMethods;

  const onClose = useCallback(
    (eventId?: string) => {
      const pathname =
        typeof eventId !== 'string' ? '/calendar' : `/calendar/events/${eventId}/details`;
      navigate({ pathname, search: location.search });
    },
    [navigate, location.search],
  );

  const onSubmit = useCallback(
    async (data: IEventFormInput, openAfter: boolean = false) => {
      await editEvent({
        ...data,
        _id: event._id,
      }).unwrap();
      toast({
        description: t('edit_success'),
        status: 'success',
        position: 'top',
      });
      onClose(openAfter ? event._id : undefined);
    },
    [editEvent, toast, event._id, t, onClose],
  );

  const onForceSubmit = useCallback(
    async (openAfter: boolean = false) => {
      const formValues = getValues();
      await editEvent({
        _id: event._id,
        ...formValues,
        ignoreWarnings: true,
      }).unwrap();
      toast({
        description: t('edit_success'),
        status: 'success',
        position: 'top',
      });
      onClose(openAfter ? event._id : undefined);
    },
    [editEvent, getValues, event._id, toast, t, onClose],
  );

  const onCancelForceSubmit = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <FormProvider {...formMethods}>
      <EventForm
        isLoading={isEditLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        header={t('event_edit')}
        submitIcon={<Icon as={FiEdit2} />}
        submitText={t('edit_maj')}
        eventId={event._id}
      />
      <EventConfirmWarning
        confirmTitle={t('edit_confirm', { ns: 'components/event-form' })}
        descriptionType="edit"
        isGroupMode={event.registrationType === 'group'}
        error={error}
        roomsRefSelected={getValues().roomsRef}
        onConfirm={onForceSubmit}
        onCancel={onCancelForceSubmit}
      />
    </FormProvider>
  );
}

export const EventEditForm = React.memo(_EventEditForm);
