import { Icon, IconButton, useToast } from '@chakra-ui/react';
import { EventSlotSingleEntry } from '@epitech/ops-panoramix-events-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

import { EventForceRegisterActionButton } from '@/components/events/force-register';
import { ConfirmPopover } from '@/components/ui-elements/ConfirmPopover';
import { environment } from '@/config/environment';
import { BASE_OPACITY_TRANSITION } from '@/config/theme';
import { useForceUnregisterFromEventMutation } from '@/store/services/events';

type ForceRegisterAppointmentSingleActionButtonProps = {
  eventId: string;
  slot: EventSlotSingleEntry;
  slotIdx: number;
  isLoading: boolean;
  isSlotFull: boolean;
  userType: 'users' | 'groups';
};

function _ForceRegisterAppointmentSingleActionButton({
  eventId,
  slot,
  slotIdx,
  isLoading: _isLoading,
  isSlotFull,
  userType,
}: ForceRegisterAppointmentSingleActionButtonProps) {
  const { t } = useTranslation('components/events');
  const isGroupMode = userType === 'groups';
  const [forceUnregisterFromEvent, { isLoading: isUnregisterLoading }] =
    useForceUnregisterFromEventMutation();
  const toast = useToast();

  const isLoading = _isLoading || isUnregisterLoading;

  const onUnregister = useCallback(async () => {
    const toUnregister = isGroupMode ? slot.registeredGroup : slot.registeredUser;

    if (!toUnregister) {
      return;
    }

    await forceUnregisterFromEvent({
      eventId,
      type: isGroupMode ? 'projectGroups' : 'users',
      toRegisters: [toUnregister],
      slotIdx: slotIdx,
    }).unwrap();

    toast({
      description: t('success_desinscription'),
      status: 'success',
      position: 'top',
    });
  }, [
    toast,
    t,
    forceUnregisterFromEvent,
    eventId,
    isGroupMode,
    slotIdx,
    slot.registeredGroup,
    slot.registeredUser,
  ]);

  if (isSlotFull) {
    return (
      <ConfirmPopover
        onConfirm={onUnregister}
        isLoading={isLoading}
        resourceName={slot.registeredUser?.firstName || slot.registeredGroup?.name}
        confirmLabel={t('unregister_confirm')}
        confirmButtonLabel={t('unregister_btn_label')}
        renderTrigger={() => (
          <IconButton
            colorScheme="red"
            icon={<Icon as={FiX} />}
            aria-label={t('desinscription')}
            size="xs"
          />
        )}
      />
    );
  }
  return (
    <EventForceRegisterActionButton
      userType={userType}
      eventId={eventId}
      slot={slot}
      slotIdx={slotIdx}
      buttonProps={{
        opacity: 0,
        _groupHover: {
          opacity: 1,
        },
        transition: BASE_OPACITY_TRANSITION,
      }}
    />
  );
}

export const ForceRegisterAppointmentSingleActionButton = React.memo(
  _ForceRegisterAppointmentSingleActionButton,
);

if (environment.__DEV__) {
  ForceRegisterAppointmentSingleActionButton.displayName =
    'ForceRegisterAppointmentSingleActionButton';
}
