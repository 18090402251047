import {
  HStack,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/config/environment';

export type SlotListProps = {
  isGroupMode?: boolean;
  staffActionButton?: JSX.Element | null;
  roomActionButton?: JSX.Element | null;
  tableLayout?: 'auto' | 'fixed';
};

function _SlotListBase({
  children,
  isGroupMode = false,
  staffActionButton,
  roomActionButton,
  tableLayout = 'auto',
  ...containerProps
}: React.PropsWithChildren<SlotListProps> & TableContainerProps): React.ReactElement {
  const { t } = useTranslation('components/events');

  return (
    <TableContainer
      w="full"
      {...containerProps}
      border="1px"
      borderColor={'separatorSecondaryBorderColor'}
      overflowX="visible"
      overflowY="visible"
    >
      <Table variant="simple" size="md" layout={tableLayout} position="relative">
        <Thead position="sticky" top="0" bgColor="containerBgColor">
          <Tr>
            <Th w={24}>
              <Text textStyle="muted-header">{t('date')}</Text>
            </Th>
            <Th w={48}>
              <Text textStyle="muted-header">{t('hour')}</Text>
            </Th>
            <Th w={250}>
              <HStack alignItems="center" gap={2}>
                <Text flex={1} textStyle="muted-header">
                  {t('staffs')}
                </Text>
                {staffActionButton}
              </HStack>
            </Th>
            <Th>
              {isGroupMode ? (
                <Text textStyle="muted-header">{t('groups')}</Text>
              ) : (
                <Text textStyle="muted-header">{t('students')}</Text>
              )}
            </Th>
            <Th>
              <HStack alignItems="center" gap={2}>
                <Text flex={1} textStyle="muted-header">
                  {t('rooms')}
                </Text>
                {roomActionButton}
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}

export const SlotListBase = React.memo(_SlotListBase);

if (environment.__DEV__) {
  SlotListBase.displayName = 'SlotListBase';
}
