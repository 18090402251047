import type {
  EventSlotMultipleEntry,
  EventSlotSingleEntry,
} from '@epitech/ops-panoramix-events-types';
import React, { useMemo } from 'react';

import { environment } from '@/config/environment';
import { useGetEventQuery } from '@/store/services/events';

import { useEventDetailsContext } from '../../detailsProvider/useEventDetailsContext';
import { SlotRegisteredItem } from './RegisteredItem';
import { SlotRegisteredItemBreak } from './RegisteredItem/RegisteredItemBreak';
import { SlotRegisteredListBase } from './RegisteredListBase';

type SlotRegisteredOccurenceGroupProps = {
  slots: ((EventSlotSingleEntry | EventSlotMultipleEntry) & {
    oldIndex?: number | undefined;
  })[];
};

function _SlotRegisteredOccurenceGroup({ slots = [] }: SlotRegisteredOccurenceGroupProps) {
  const {
    eventId,
    isAlreadyRegistered,
    canRegister,
    toRegister,
    isGroupMode,
    type,
    canForceRegisterRooms,
    canForceRegisterUsers,
    canForceRegisterStaffs,
  } = useEventDetailsContext();
  const { maxRegistrationsBySlot } = useGetEventQuery(eventId, {
    selectFromResult: ({ data }) => ({
      maxRegistrationsBySlot:
        data?.type === 'appointment_multiple' ? data.maxRegistrationsBySlot : undefined,
    }),
  });

  const allSlotsIdx = useMemo(() => slots.map((slot, index) => slot.oldIndex || index), [slots]);

  if (type === 'course') {
    return null;
  }

  return (
    <SlotRegisteredListBase allSlotsIdx={allSlotsIdx} layerStyle="container">
      {slots.map((slot, idx) =>
        slot.type === 'slot' || slot.type === 'slot_multiple' ? (
          <SlotRegisteredItem
            key={allSlotsIdx[idx]}
            eventId={eventId}
            type={type}
            maxRegistrationsBySlot={maxRegistrationsBySlot}
            allowRegistration={canRegister}
            isAlreadyRegistered={isAlreadyRegistered}
            slot={slot}
            slotIdx={allSlotsIdx[idx]}
            toRegister={toRegister}
            isGroupMode={isGroupMode}
            canForceRegisterRooms={canForceRegisterRooms}
            canForceRegisterUsers={canForceRegisterUsers}
            canForceRegisterStaffs={canForceRegisterStaffs}
          />
        ) : (
          <SlotRegisteredItemBreak slot={slot} key={allSlotsIdx[idx]} />
        ),
      )}
    </SlotRegisteredListBase>
  );
}

export const SlotRegisteredOccurenceGroup = React.memo(_SlotRegisteredOccurenceGroup);

if (environment.__DEV__) {
  SlotRegisteredOccurenceGroup.displayName = 'SlotRegisteredOccurenceGroup';
}
