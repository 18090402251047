import { useToast } from '@chakra-ui/react';
import type {
  EventAppointmentEntry,
  EventAppointmentMultipleEntry,
} from '@epitech/ops-panoramix-events-types';
import type { Stored } from '@epitech/ops-panoramix-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ForceRegisterTabListWrapper } from '@/components/events/force-register/shared/TabListWrapper';
import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { type IUserOption } from '@/components/users/select';
import { environment } from '@/config/environment';
import { useForceRegisterToEventMutation } from '@/store/services/events';

import { ForceRegisterStaffsBase } from './Base';

export type ForceRegisterStaffsAppointmentProps = {
  event: Stored<EventAppointmentEntry> | Stored<EventAppointmentMultipleEntry>;
  slotIdx: number;
  onClose: () => void;
};

function _ForceRegisterStaffsAppointment({
  event,
  onClose,
  slotIdx,
}: ForceRegisterStaffsAppointmentProps) {
  const [forceRegisterStaffsToEvent, { isLoading: isRegisterLoading }] =
    useForceRegisterToEventMutation();
  const { t } = useTranslation('components/events');
  const eventSlot = useMemo(() => event.slots[slotIdx], [event.slots, slotIdx]);

  const toast = useToast();

  const onSubmitHandler = useCallback(
    (selectedOptions: IUserOption[]) => {
      const selectedUsersRefs = selectedOptions.map(opt => opt.value);

      forceRegisterStaffsToEvent({
        eventId: event._id!,
        toRegisters: selectedUsersRefs,
        type: 'staffs',
        slotIdx,
      });
      toast({
        description: t('staff_register_success'),
        status: 'success',
        position: 'top',
      });
      onClose();
    },
    [onClose, event._id, slotIdx, forceRegisterStaffsToEvent, t, toast],
  );

  const filterOption = useCallback(
    (option: FilterOptionOption<IUserOption>) => {
      return !(
        eventSlot &&
        eventSlot.registeredStaffs.some(
          registeredStaff => registeredStaff._id === option.data.value._id,
        )
      );
    },
    [eventSlot],
  );

  return (
    <ForceRegisterTabListWrapper
      eventId={event._id}
      type="staffs"
      registeredItems={eventSlot.registeredStaffs}
      slotIdx={slotIdx}
    >
      <ForceRegisterStaffsBase
        filterOption={filterOption}
        onSubmit={onSubmitHandler}
        isLoading={isRegisterLoading}
        searchParams={{
          cohortGroups: event.cohortGroups,
          moduleRef: event.moduleRef,
        }}
      />
    </ForceRegisterTabListWrapper>
  );
}

export const ForceRegisterStaffsAppointment = React.memo(_ForceRegisterStaffsAppointment);

if (environment.__DEV__) {
  ForceRegisterStaffsAppointment.displayName = 'ForceRegisterStaffsAppointment';
}
