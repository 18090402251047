import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, Checkbox, Flex, Input, Tag } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  users: Array<{
    id: number;
    name: string;
    present: boolean;
    realIndex: number;
  }>;
}

function ListUsers({ users }: IProps) {
  const { register, watch, setValue } = useFormContext();
  const { t } = useTranslation('components/followups/followups');

  return (
    <FormControl>
      <FormLabel>{t('Presence')}</FormLabel>
      <Flex wrap="wrap" gap={2} mt={2}>
        {users.map((user, index) => (
          <Box key={`user-${user.id}`} as={Tag} p={2} bgColor="tagCheckboxBgColor">
            <Input type="hidden" {...register(`tags[${user.realIndex}].type`)} value={'user'} />
            <Input
              type="hidden"
              {...register(`tags[${user.realIndex}].foreign_id`)}
              value={user.id}
            />
            <Checkbox
              isChecked={watch(`tags[${user.realIndex}].present`)}
              onChange={e => setValue(`tags[${user.realIndex}].present`, e.target.checked)}
            >
              {user.name}
            </Checkbox>
          </Box>
        ))}
      </Flex>
    </FormControl>
  );
}

export default ListUsers;
