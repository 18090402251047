import { EventSlotBreakInput, EventSlotInput } from '@epitech/ops-panoramix-events-types';
import { addMinutes, differenceInMinutes } from 'date-fns';

/** Since `start` and `end` can be undefined, our `breaks` variables can be wrongly populated */
export const filterUnsetBreaks = (breaks?: EventSlotBreakInput[]) => {
  if (!breaks) return [];
  return breaks.filter(b => b.start && b.end);
};

function isStrictlyWithinInterval(date: Date, interval: { start: Date; end: Date }) {
  return date >= interval.start && date <= interval.end;
}

function getBreaksDuration(breaks: EventSlotInput['breaks']): number {
  if (!breaks) return 0;
  return breaks.reduce((acc, current) => {
    const { start, end } = current;
    if (start === null || end === null) {
      return acc;
    }
    return acc + differenceInMinutes(end, start);
  }, 0);
}

export function getEndDate(start: Date, numberOfSlots: number, slotState: EventSlotInput): Date {
  const { duration, breakDuration, occurence } = slotState;
  const numberOfSlotPeriods = Math.ceil(numberOfSlots / (occurence === 0 ? 1 : occurence));
  const endDateWithoutBreaks = addMinutes(start, numberOfSlotPeriods * (duration + breakDuration));

  // XXX: Fix edge case with multiples breaks
  const breaks = filterUnsetBreaks(slotState.breaks).filter(b => {
    if (b.start !== null && b.end !== null)
      return isStrictlyWithinInterval(b.start, { start, end: endDateWithoutBreaks });
  });

  const breaksCompensation = breaks.length * (breakDuration * 2);
  const totalBreaksDuration =
    getBreaksDuration(breaks) + (numberOfSlotPeriods * breakDuration - breaksCompensation);

  const endDate = addMinutes(start, numberOfSlotPeriods * duration + totalBreaksDuration);
  const isInBreakPeriod = breaks.find(breakPeriod => {
    if (breakPeriod.start !== null && breakPeriod.end !== null)
      isStrictlyWithinInterval(endDate, { start: breakPeriod.start, end: breakPeriod.end });
  });
  return isInBreakPeriod && isInBreakPeriod['start'] !== null ? isInBreakPeriod['start'] : endDate;
}
