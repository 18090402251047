import {
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react';
import React from 'react';
import { FiX } from 'react-icons/fi';

import SkeletonForm from './Form';

interface IProps {
  onClose: () => void;
}

function _FollowUpSkeleton({ onClose }: IProps) {
  return (
    <>
      <Modal
        motionPreset="none"
        isOpen={true}
        onClose={onClose}
        size="2xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent padding={0} position="relative">
          <HStack justify="space-between" width="full" px={6} align="center">
            <ModalHeader fontSize="2xl" as="h1" flex={1} px={0}>
              <Skeleton height="1.8em" width="10em"></Skeleton>
            </ModalHeader>
            <IconButton
              aria-label="Close"
              icon={<FiX />}
              variant="ghost"
              onClick={onClose}
              mr={-4}
            />
          </HStack>
          <SkeletonForm />
        </ModalContent>
      </Modal>
    </>
  );
}

const FollowUpSkeleton = React.memo(_FollowUpSkeleton);
export default FollowUpSkeleton;
