import { useAbility } from '@casl/react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ICohortType } from '@epitech/ops-panoramix-types';
import { ActionMeta, MultiValue, SingleValue } from 'chakra-react-select';
import React, { ComponentProps, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';

import { useEventSearch } from '@/components/events/useEventSearch';
import {
  type IModuleOption,
  ModuleMultiValue,
  ModuleOptionMultiValue,
  useModuleSearch,
} from '@/components/modules/select';
import { Can } from '@/components/ui-elements/Can';
import { AsyncSelect, AsyncSelectMemo } from '@/components/ui-elements/Select/Async';
import { IOption } from '@/components/ui-elements/Select/types';
import { getCohortOption } from '@/components/users/select/helpers';
import { AbilityContext } from '@/config/providers/ability.context';
import { useLazySearchCohortsQuery } from '@/store/services/users';

import { EventActionButtons, IEventFilterActionButtonsProps } from './EventActionButton';
import { FilterLabel } from './Label';
import { getActiveFilters, ICalendarFilters } from './utils';

export interface ICalendarFiltersProps {
  goToDate: IEventFilterActionButtonsProps['goToDate'];
  upsertFilters: (value: Partial<ICalendarFilters>) => void;
  filters: ICalendarFilters;
  resetFilterEntry: (key: keyof ICalendarFilters) => void;
  excludeFilters: string[];
}

function _CalendarFilters({
  goToDate,
  upsertFilters,
  resetFilterEntry,
  filters,
  excludeFilters,
}: ICalendarFiltersProps) {
  const searchModules = useModuleSearch();
  const searchEvents = useEventSearch();
  const [searchCohorts] = useLazySearchCohortsQuery();
  const { t } = useTranslation('layouts/calendar/Filters');
  const ability = useAbility(AbilityContext);

  const onSearchCohort = useCallback(
    async (
      type: ICohortType,
      search: string,
      callback: ((options: IOption[]) => void | Promise<IOption[]>) | undefined,
      limit?: number,
      offset?: number,
    ) => {
      const result = await searchCohorts({ search, type, limit, offset }, true).unwrap();
      return result.map(getCohortOption);
    },
    [searchCohorts],
  );

  const searchCurriculums = useMemo(
    () => onSearchCohort.bind(null, 'curriculum'),
    [onSearchCohort],
  );
  const searchPromotions = useMemo(() => onSearchCohort.bind(null, 'promotion'), [onSearchCohort]);
  const searchCities = useMemo(() => onSearchCohort.bind(null, 'city'), [onSearchCohort]);

  const onVisibleFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      upsertFilters({ visible: e.target.checked });
    },
    [upsertFilters],
  );

  const onModuleFilterChange = useCallback(
    (newValue: MultiValue<IModuleOption>) => {
      const modules = newValue.map(v => v.value.code);

      if (modules.length) {
        upsertFilters({ modulesCode: modules });
      } else {
        resetFilterEntry('modulesCode');
      }
    },
    [upsertFilters, resetFilterEntry],
  );

  const onSingleFilterChange = useCallback(
    (newValue: SingleValue<IOption>, actionMeta: ActionMeta<IOption>) => {
      const action = actionMeta.name as keyof ICalendarFilters;
      if (newValue?.value) {
        upsertFilters({
          [action]: newValue.value,
        });
      } else resetFilterEntry(action);
    },
    [upsertFilters, resetFilterEntry],
  );

  const numberOfActiveFilters = useMemo(() => {
    const activeFilters = getActiveFilters(filters);
    if (activeFilters?.visible && ability.cannot("create", "event")) {
      excludeFilters.push("visible");
    }
    return Object.keys(activeFilters).filter(el => !excludeFilters.includes(el)).length;
  }, [filters, excludeFilters, ability]);

  return (
    <Accordion allowToggle layerStyle="base" shadow="none" borderBottomRadius="none" minW="300px">
      <AccordionItem>
        <AccordionButton>
          <Text flex="1" textAlign="left" fontWeight="bold" fontSize="md">
            {t('filter')}&nbsp;
            <Text as="span" fontFamily="mono">
              ({numberOfActiveFilters})
            </Text>
          </Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Box mx={4}>
            {!excludeFilters.includes('search') ? (
              <>
                <FilterLabel label="eventFilter" />
                <Flex mb={4} flexWrap="wrap">
                  <Box flex="1" minW={{ base: '100%', 'md-': 1 }}>
                    <AsyncSelect
                      name="search"
                      size="sm"
                      onChange={onSingleFilterChange}
                      loadOptions={searchEvents}
                      htmlDecode={true}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      noOptionsMessage={() => t('search_event_no_options')}
                      infiniteScroll={true}
                    />
                  </Box>
                  <Box ml={4}>
                    <EventActionButtons
                      goToDate={goToDate}
                      isDisabled={!filters.search}
                      eventRef={filters.search}
                    />
                  </Box>
                </Flex>
              </>
            ) : null}
          </Box>
          <Flex
            direction={{ base: 'column', xl: 'row' }}
            flexWrap={{ base: 'nowrap', xl: 'wrap' }}
            mx={4}
            mb={4}
          >
            <Box flex={{ base: '1', xl: '50%' }} pr={{ base: 0, xl: 2 }} boxSizing="border-box">
              <Stack direction="column" alignItems="flex-start">
                {!excludeFilters.includes('module') ? (
                  <Box mb={4} minW="100%">
                    <FilterLabel label="moduleFilter" />
                    <AsyncSelect<IModuleOption, true>
                      isMulti
                      name="module"
                      size="sm"
                      onChange={onModuleFilterChange}
                      loadOptions={searchModules}
                      defaultValue={filters.modulesCode.map(code => ({
                        value: { code } as IModuleOption['value'], // todo: should fetch the modules by filters.modulesCode on initial render to pass full refs
                        label: code,
                      }))}
                      defaultOptions
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      htmlDecode={true}
                      noOptionsMessage={() => t('search_module_no_options')}
                      filterOption={opt => !filters.modulesCode.includes(opt.data.value.code)}
                      components={{ Option: ModuleOptionMultiValue, MultiValue: ModuleMultiValue }}
                      infiniteScroll={true}
                    />
                  </Box>
                ) : null}
                {!excludeFilters.includes('visible') ? (
                  <Can I="create" an="event">
                    <Checkbox
                      defaultChecked={filters.visible}
                      mb={4}
                      onChange={onVisibleFilterChange}
                      >
                      {t('visibleFilter')}
                      <Tooltip label={t('visibleFilterTooltip')} placement="right">
                        <span>
                          <Icon as={FiInfo} ml={2} verticalAlign="middle" />
                        </span>
                      </Tooltip>
                    </Checkbox>
                  </Can>
                ) : null}
              </Stack>
            </Box>
            <Box flex={{ base: '1', xl: '50%' }} pl={{ base: 0, xl: 2 }} boxSizing="border-box">
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                {!excludeFilters.includes('curriculum') ? (
                  <Box>
                    <FilterLabel label="cohortCurriculumFilter" />
                    <AsyncSelectMemo
                      name="curriculum"
                      size="sm"
                      onChange={
                        onSingleFilterChange as ComponentProps<typeof AsyncSelectMemo>['onChange']
                      }
                      loadOptions={searchCurriculums}
                      defaultOptions
                      htmlDecode={true}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      noOptionsMessage={() => t('search_cohort_no_options')}
                      infiniteScroll={true}
                    />
                  </Box>
                ) : null}
                {!excludeFilters.includes('promotion') ? (
                  <Box>
                    <FilterLabel label="cohortPromotionFilter" />
                    <AsyncSelectMemo
                      name="promotion"
                      size="sm"
                      onChange={
                        onSingleFilterChange as ComponentProps<typeof AsyncSelectMemo>['onChange']
                      }
                      loadOptions={searchPromotions}
                      defaultOptions
                      htmlDecode={true}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      noOptionsMessage={() => t('search_cohort_no_options')}
                      infiniteScroll={true}
                    />
                  </Box>
                ) : null}
                {!excludeFilters.includes('city') ? (
                  <Box>
                    <FilterLabel label="cohortCityFilter" />
                    <AsyncSelectMemo
                      name="city"
                      size="sm"
                      onChange={
                        onSingleFilterChange as ComponentProps<typeof AsyncSelectMemo>['onChange']
                      }
                      loadOptions={searchCities}
                      defaultOptions
                      htmlDecode={true}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      noOptionsMessage={() => t('search_cohort_no_options')}
                      infiniteScroll={true}
                    />
                  </Box>
                ) : null}
              </SimpleGrid>
            </Box>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export const CalendarFilters = React.memo(_CalendarFilters);
