import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronRight } from 'react-icons/fi';

import GoToExternalSourceTag from '@/components/ui-elements/GoToExternalSourceTag';
import { LabelData } from '@/components/ui-elements/LabelData';
import { TextDivider } from '@/components/ui-elements/TextDivider';
import { environment } from '@/config/environment';
import { getMoodleLinkToActivity, getMoodleLinkToCourse } from '@/lib/helpers/utils';
import { useGetEventSanitizedQuery } from '@/lib/hooks/useGetEventSanitizedQuery';

import { useEventDetailsContext } from '../detailsProvider/useEventDetailsContext';
import { EventRegisteredItem } from '../registered/Item';
import { EventRoomsDeprecationWarning } from '../Warning/Rooms/Deprecated';
import { EventDetailsCohorts } from './Cohorts';
import { EventDetailsDate } from './Date';

function _EventDetailsMetadata() {
  const { eventId, isAlreadyRegistered, toRegister } = useEventDetailsContext();
  const { data: event } = useGetEventSanitizedQuery(eventId);

  const { t } = useTranslation(['layouts/calendar', 'components/events', 'layouts/calendar/Option']);
  const moduleSource = event?.moduleRef?.tpIds[0].source;

  const externalCourseLink = useMemo(() => {
    if (event?.moduleRef?.tpIds && moduleSource) {
      return getMoodleLinkToCourse(moduleSource, event.moduleRef.tpIds[0].id);
    }
  }, [event?.moduleRef?.tpIds, moduleSource]);

  const externalActivityLink = useMemo(() => {
    if (event?.moduleRef?.activityRef && moduleSource) {
      return getMoodleLinkToActivity(moduleSource, event.moduleRef.activityRef);
    }
  }, [moduleSource, event?.moduleRef?.activityRef]);

  if (!event) {
    return null;
  }

  return (
    <VStack gap={2} width="full">
      {event.moduleRef && (
        <HStack spacing={2} w="full">
          <GoToExternalSourceTag
            label={t('go_to_module_source', {
              source: capitalize(moduleSource),
              ns: 'components/events',
            })}
            href={externalCourseLink}
            size="lg"
            colorScheme="blue"
            _hover={{
              bgColor: 'blue.300',
              transform: 'scale(1.02)',
            }}
          >
            {event.moduleRef.name}
          </GoToExternalSourceTag>
          {event.moduleRef?.activityRef && (
            <>
              <Icon as={FiChevronRight} color="secondFontColor" />
              <GoToExternalSourceTag
                label={t('go_to_activity_source', {
                  source: capitalize(moduleSource),
                  ns: 'components/events',
                })}
                size="lg"
                href={externalActivityLink}
                colorScheme="teal"
                _hover={{
                  bgColor: 'teal.400',
                  transform: 'scale(1.02)',
                }}
              >
                {event.moduleRef?.activityRef.name}
              </GoToExternalSourceTag>
            </>
          )}
        </HStack>
      )}
      <LabelData label={t('date')}>
        <EventDetailsDate start={event.start} end={event.end} />
      </LabelData>
      {event.roomsRef?.length > 0 && (
        <LabelData label={t('room')}>
          <HStack spacing={2} divider={<TextDivider />}>
            {event.roomsRef.map(room => (
              <Flex flexDirection="row" key={room._id}>
                <Text>{room.name}</Text>              
                {room.disabled && 
                  <EventRoomsDeprecationWarning />
                }
                </Flex>
            ))}
          </HStack>
        </LabelData>
      )}
      {event.cohortGroups.length > 0 && (
        <LabelData label={t('cohort')}>
          <EventDetailsCohorts cohorts={event.cohortGroups} />
        </LabelData>
      )}
      {isAlreadyRegistered && (
        <LabelData label={t('subscribed_as')}>
          <EventRegisteredItem isGroupMode={event.registrationType === 'group'} item={toRegister} />
        </LabelData>
      )}
    </VStack>
  );
}

export const EventDetailsMetadata = React.memo(_EventDetailsMetadata);

if (environment.__DEV__) {
  EventDetailsMetadata.displayName = 'EventDetailsMetadata';
}
