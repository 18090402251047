import {
  Button,
  ButtonGroup,
  FocusLock,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
  PopoverProps,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IConfirmPopoverContentProps {
  confirmLabel: string;
  confirmButtonLabel?: string;
  onConfirm: () => void;
  resourceName?: string;
  isLoading?: boolean;
}

function _ConfirmPopoverContent({
  confirmLabel,
  onConfirm,
  resourceName,
  onClose,
  isLoading = false,
  confirmButtonLabel,
}: IConfirmPopoverContentProps & Partial<PopoverProps>) {
  const { t } = useTranslation('actions');

  return (
    <FocusLock restoreFocus={true}>
      <PopoverBody px={3} py={2}>
        {confirmLabel}
        {resourceName && (
          <Text fontWeight="semibold" as="span">
            &nbsp;
            {resourceName}
          </Text>
        )}
        &nbsp; ?
      </PopoverBody>
      <PopoverArrow />
      <PopoverFooter display="flex" justifyContent="flex-end" as={ButtonGroup}>
        <Button onClick={onClose} colorScheme="gray" size="sm" variant="outline">
          {t('action_cancel')}
        </Button>
        <Button onClick={onConfirm} colorScheme="red" isLoading={isLoading} size="sm">
          {confirmButtonLabel || t('action_delete')}
        </Button>
      </PopoverFooter>
    </FocusLock>
  );
}

export const ConfirmPopoverContent = React.memo(_ConfirmPopoverContent);
