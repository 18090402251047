import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RegisterToEventArgs,
  useForceUnregisterFromEventMutation,
  useUnregisterFromEventMutation,
} from '@/store/services/events';

/**
 * A wrapper around `useUnregisterFromEvent` and `useForceUnregisterFromEvent` mutations to give it the same signature.
 * Useful for RegisteredItems that manage the unregistration process.
 */
export function useEventUnregistration({
  canForceRegister,
  ...baseParams
}: RegisterToEventArgs & {
  canForceRegister?: boolean;
}) {
  const { t } = useTranslation('components/events');
  const toast = useToast();
  const [unregisterFromEvent, { isLoading: isUnregisterLoading }] =
    useUnregisterFromEventMutation();
  const [forceUnregisterFromEvent, { isLoading: isForceUnregisterLoading }] =
    useForceUnregisterFromEventMutation();

  const unregister = useCallback(async () => {
    const { toRegister, slotIdx } = baseParams;

    if (canForceRegister) {
      await forceUnregisterFromEvent({
        ...baseParams,
        toRegisters: [toRegister],
        slotIdx,
      }).unwrap();
    } else {
      await unregisterFromEvent({
        ...baseParams,
        toRegister,
        slotIdx,
      }).unwrap();
    }
    toast({
      description: t(
        `${baseParams.type === 'projectGroups' ? 'group' : 'user'}_unregister_success`,
      ),
      status: 'success',
      position: 'top',
    });
  }, [baseParams, canForceRegister, forceUnregisterFromEvent, toast, t, unregisterFromEvent]);

  return [unregister, { isLoading: isUnregisterLoading || isForceUnregisterLoading }] as const;
}
