import {
  FocusLock,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  Spinner,
  Text,
  usePopoverContext,
} from '@chakra-ui/react';
import { EventCourseEntry } from '@epitech/ops-panoramix-events-types';
import type { Stored, UserRef } from '@epitech/ops-panoramix-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IUserSearchForm, UserSearchForm } from '@/components/users/SearchForm';
import { environment } from '@/config/environment';
import { useForceUnregisterUsersWithMetadataFromEventMutation } from '@/store/services/events';

export type ForceUnregisterUsersActionButtonContent = {
  event: Stored<EventCourseEntry>;
};

function _ForceUnregisterUsersFromMetadataActionButtonContent({
  event,
}: ForceUnregisterUsersActionButtonContent) {
  const { t } = useTranslation('components/events');
  const { onClose } = usePopoverContext();

  const [unregisterFromEvent, { isLoading: isUnregisterLoading }] =
    useForceUnregisterUsersWithMetadataFromEventMutation();

  const onSubmit = useCallback(
    ({ cohortGroups, moduleRef }: IUserSearchForm) => {
      unregisterFromEvent({ eventId: event._id, cohortGroups, moduleRef });
      onClose();
    },
    [event._id, unregisterFromEvent, onClose],
  );

  if (!event || event.type !== 'course') {
    throw new Error('Only course events can unregister users');
  }

  /** Store IDs in an array for faster filter lookup */
  const registeredUserIds = useMemo(
    () => event?.registeredUsers.map(user => user._id) || [],
    [event?.registeredUsers],
  );

  const filterUnregisteredUsersFromPreview = useCallback(
    (user: UserRef) => registeredUserIds.includes(user._id),
    [registeredUserIds],
  );

  return (
    <FocusLock restoreFocus={true}>
      <PopoverHeader textStyle="popover-header" px={0} pt={0} pb={4} w="full" display="flex">
        <PopoverCloseButton p={0} top={3.5} />
        <Text>{t('menu_title_unregister_students')}</Text>
      </PopoverHeader>
      <PopoverBody mt={4}>
        {!event ? (
          <Spinner />
        ) : (
          <UserSearchForm
            cohortGroups={event.cohortGroups}
            moduleRef={event.moduleRef ? event.moduleRef : undefined}
            onSubmit={onSubmit}
            isSubmitLoading={isUnregisterLoading}
            submitBtnLabel={t('unregister')}
            filterUsersInPreview={filterUnregisteredUsersFromPreview}
          />
        )}
      </PopoverBody>
    </FocusLock>
  );
}

export const EventForceUnregisterUsersFromMetadataActionButtonContent = React.memo(
  _ForceUnregisterUsersFromMetadataActionButtonContent,
);

if (environment.__DEV__) {
  EventForceUnregisterUsersFromMetadataActionButtonContent.displayName =
    'EventForceUnregisterUsersFromMetadataActionButtonContent';
}
