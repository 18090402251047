import type {
  EventSlotEntry,
  EventSlotMultipleEntry,
  EventSlotSingleEntry,
  IEventType,
} from '@epitech/ops-panoramix-events-types';
import React from 'react';

import {
  SlotRegisteredItemAppointmentMultiple,
  SlotRegisteredItemAppointmentMultipleProps,
} from '@/components/events/slots/RegisteredList/RegisteredItem/appointments/multiple/RegisteredItem';
import {
  SlotRegisteredItemAppointmentSingle,
  SlotRegisteredItemAppointmentSingleProps,
} from '@/components/events/slots/RegisteredList/RegisteredItem/appointments/single/RegisteredItem';
import { environment } from '@/config/environment';

export type SlotRegisteredItemProps = Omit<
  SlotRegisteredItemAppointmentMultipleProps & SlotRegisteredItemAppointmentSingleProps,
  'slot' | 'maxRegistrationsBySlot'
> & {
  maxRegistrationsBySlot?: number;
  type: Extract<IEventType, 'appointment' | 'appointment_multiple'>;
  slot: EventSlotEntry;
};

function _SlotRegisteredItem(props: SlotRegisteredItemProps) {
  switch (props.type) {
    case 'appointment':
      return (
        <SlotRegisteredItemAppointmentSingle {...props} slot={props.slot as EventSlotSingleEntry} />
      );
    case 'appointment_multiple':
      if (!props.maxRegistrationsBySlot || isNaN(props.maxRegistrationsBySlot)) {
        throw new Error('maxRegistrationsBySlot is required for appointment_multiple');
      }
      return (
        <SlotRegisteredItemAppointmentMultiple
          {...props}
          maxRegistrationsBySlot={props.maxRegistrationsBySlot}
          slot={props.slot as EventSlotMultipleEntry}
        />
      );
    default:
      return null;
  }
}

export const SlotRegisteredItem = React.memo(_SlotRegisteredItem);

if (environment.__DEV__) {
  SlotRegisteredItem.displayName = 'SlotRegisteredItem';
}
