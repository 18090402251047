import { chakraComponents, OptionProps } from 'chakra-react-select';
import React from 'react';

import { GroupItem } from '../GroupItem';
import type { IProjectGroupOption } from './helpers';

export function GroupOption<IsMulti extends boolean = false>({
  children,
  ...props
}: React.PropsWithChildren<OptionProps<IProjectGroupOption, IsMulti>>) {
  const { data } = props;

  return (
    <chakraComponents.Option {...props}>
      <GroupItem w="full" value={data.value} triggerVariant="solid" />
    </chakraComponents.Option>
  );
}
