import { Badge, Box, ModalHeader } from '@chakra-ui/react';
import { decode } from 'he';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEventDetailsContext } from '../detailsProvider/useEventDetailsContext';
import { EventActionButtons } from './ActionButtons';

function _EventDetailsHeader() {
  const { t } = useTranslation('layouts/calendar');
  const { title, type } = useEventDetailsContext();

  return (
    <ModalHeader
      fontSize="2xl"
      as="h1"
      gap={4}
      py={0}
      display="flex"
      flexDirection="row"
      alignItems="baseline"
    >
      <Box p="0" mr="auto">
        {decode(title)}
        <Badge colorScheme="blue" ml="4" size="lg">
          {t(type)}
        </Badge>
      </Box>
      <EventActionButtons />
    </ModalHeader>
  );
}

export const EventDetailsHeader = React.memo(_EventDetailsHeader);
