import { Flex, Icon, IconButton, List, ListItem } from '@chakra-ui/react';
import { MultiValue } from 'chakra-react-select';
import React, { ComponentType } from 'react';
import { FiX } from 'react-icons/fi';

import type { IBaseItemComponentProps, IBaseItemOption } from '@/components/types';
import { BASE_OPACITY_TRANSITION } from '@/config/theme';

type SearchMenuSelectedListProps<T extends IBaseItemOption> = {
  ValueComponent: ComponentType<IBaseItemComponentProps<T['value']>>;
  selected: MultiValue<T>;
  onDelete: (value: T['value']) => void;
};

export function SearchMenuSelectedList<T extends IBaseItemOption>({
  selected,
  ValueComponent,
  onDelete,
}: SearchMenuSelectedListProps<T>): React.ReactElement | null {
  return selected.length ? (
    <List variant="bordered" maxHeight={300} overflowY="auto">
      {selected.map(it => (
        <Flex
          px={2}
          as={ListItem}
          key={it.value._id}
          width="full"
          role="group"
          alignItems="center"
          justifyContent="justify-between"
          gap={2}
          flexShrink={1}
        >
          <ValueComponent value={it.value} />
          <IconButton
            flexShrink={0}
            variant="ghost"
            icon={<Icon as={FiX} />}
            aria-label="unregister"
            size="xs"
            colorScheme="red"
            opacity={0}
            transition={BASE_OPACITY_TRANSITION}
            _groupHover={{
              opacity: 1,
            }}
            onClick={() => onDelete(it.value)}
          />
        </Flex>
      ))}
    </List>
  ) : null;
}
