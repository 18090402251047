import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  ButtonGroup,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { ProjectGroupRef, UserRef } from '@epitech/ops-panoramix-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamsButton } from '@/components/ui-elements/Buttons/TeamsButton';
import { EmptyListPlaceholder } from '@/components/ui-elements/Placeholders';
import { useAuth } from '@/lib/hooks/useAuth';

import { useEventDetailsContext } from '../detailsProvider/useEventDetailsContext';
import { EventForceRegisterActionButton } from '../force-register';
import { EventForceUnregisterUsersFromMetadataActionButton } from '../force-unregister/users';
import { RegisteredGroupItem, RegisteredStaffItem, RegisteredUserItem } from '../registered';

type EventRegisteredListProps = {
  registered: UserRef[] | ProjectGroupRef[];
  registeredStaffs: UserRef[];
};

function _EventRegisteredList({ registered, registeredStaffs }: EventRegisteredListProps) {
  const { t } = useTranslation('components/events');
  const { eventId, isGroupMode, canForceRegisterStaffs, canForceRegisterUsers } =
    useEventDetailsContext();
  const { user: userLogged } = useAuth();

  const usersLogin = useMemo(() => {
    return registered.flatMap(elem => {
      if ('login' in elem) {
        return elem.login;
      } else if ('usersRef' in elem) {
        return elem.usersRef.map(el => el.login);
      } else {
        return '';
      }
    });
  }, [registered]);

  if (!eventId) {
    return null;
  }

  return (
    <Accordion defaultIndex={[0, 1]} allowMultiple layerStyle="container" w="full">
      <AccordionItem>
        <AccordionButton as="div">
          <Text flex="1" textAlign="left" textStyle="muted-header">
            {t('staff_title')}
          </Text>
          {canForceRegisterStaffs && (
            <EventForceRegisterActionButton eventId={eventId} userType="staffs" />
          )}
          <AccordionIcon ml={4} />
        </AccordionButton>

        <AccordionPanel as={List} px={6} maxH="35vh" overflowY="auto">
          {registeredStaffs.length ? (
            registeredStaffs.map(registeredItem => (
              <RegisteredStaffItem
                key={registeredItem._id}
                canForceUnregister={canForceRegisterStaffs}
                user={registeredItem}
                eventId={eventId!}
              />
            ))
          ) : (
            <EmptyListPlaceholder resource={`${t('staff')} ${t('registered')}`} />
          )}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton as="div">
          <Text flex="1" textAlign="left" textStyle="muted-header">
            {isGroupMode ? t('group_title') : t('student_title')}
          </Text>
          <ButtonGroup size="xs" alignItems="center">
            <TeamsButton users={usersLogin} />
            {canForceRegisterUsers && !isGroupMode && (
              <EventForceUnregisterUsersFromMetadataActionButton
                eventId={eventId}
                colorScheme="red"
              />
            )}
            {canForceRegisterUsers && (
              <EventForceRegisterActionButton
                eventId={eventId!}
                userType={isGroupMode ? 'groups' : 'users'}
              />
            )}
          </ButtonGroup>
          <AccordionIcon ml={4} />
        </AccordionButton>
        <AccordionPanel as={List} px={0} maxH="35vh" overflowY="auto" variant="bordered" pb={2}>
          {registered.length > 0 ? (
            /** TODO: See if we can merge RegisteredGroupItem and
             * RegisteredUserItem as a single component RegisteredItem as the logic are very similar */
            registered.map(registeredItem => {
              if ('usersRef' in registeredItem) {
                const isUserRegistered = registeredItem.usersRef.some(
                  user => user._id === userLogged?._id,
                );
                return (
                  <RegisteredGroupItem
                    as={ListItem}
                    px={6}
                    key={registeredItem._id}
                    group={registeredItem}
                    eventId={eventId!}
                    unregisterBtnOnHover={canForceRegisterUsers}
                    canForceUnregister={canForceRegisterUsers}
                    canUnregister={isUserRegistered}
                    bgColor={isUserRegistered ? 'itemBgColorHover' : undefined}
                  />
                );
              }
              const isUserRegistered = registeredItem._id === userLogged?._id;
              return (
                <RegisteredUserItem
                  as={ListItem}
                  px={6}
                  unregisterBtnOnHover={canForceRegisterUsers}
                  key={registeredItem._id}
                  user={registeredItem}
                  eventId={eventId!}
                  canForceUnregister={canForceRegisterUsers}
                  canUnregister={isUserRegistered}
                  bgColor={isUserRegistered ? 'itemBgColorHover' : undefined}
                />
              );
            })
          ) : (
            <EmptyListPlaceholder
              resource={`${isGroupMode ? t('group') : t('user')} ${t('registered')}`}
            />
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export const EventRegisteredList = React.memo<EventRegisteredListProps>(_EventRegisteredList);
