import { EventAppointmentMultipleEntry } from '@epitech/ops-panoramix-events-types';
import { Stored } from '@epitech/ops-panoramix-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ForceRegisterTabListWrapper } from '@/components/events/force-register/shared/TabListWrapper';
import { SearchMenuMulti } from '@/components/ui-elements/SearchMenu/Multi';
import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { IUserOption, UserOption, useUserSearch } from '@/components/users';
import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';
import { useForceRegisterToEventMutation } from '@/store/services/events';

export type ForceRegisterAppointmentMultipleUsersProps = {
  event: Stored<EventAppointmentMultipleEntry>;
  slotIdx: number;
  onClose: () => void;
};

function _ForceRegisterAppointmentMultipleUsers({
  event,
  slotIdx,
  onClose,
}: ForceRegisterAppointmentMultipleUsersProps) {
  const { t } = useTranslation('components/events');
  const slot = useMemo(() => event.slots[slotIdx], [event.slots, slotIdx]);
  const isAddDisabled = slot.registeredUsers.length >= event.maxRegistrationsBySlot;
  const nbOfUsersLeft = event.maxRegistrationsBySlot - slot.registeredUsers.length;
  const [forceRegisterToEvent, { isLoading }] = useForceRegisterToEventMutation();

  const searchUsers = useUserSearch({
    cohortGroups: event.cohortGroups,
    moduleRef: event.moduleRef,
  });

  const onSubmitHandler = useCallback(
    (selectedUsers: IUserOption[]) => {
      forceRegisterToEvent({
        eventId: event._id!,
        toRegisters: selectedUsers.map(user => user.value),
        type: 'users',
        slotIdx,
      });
      onClose();
    },
    [onClose, event._id, forceRegisterToEvent, slotIdx],
  );

  const filterOption = (option: FilterOptionOption<IUserOption>) => {
    return !event.slots.some(slot =>
      slot.registeredUsers.some(registeredUser => registeredUser._id === option.data.value._id),
    );
  };

  const onNoOptions = useCallback(() => t('empty_user-search'), [t]);

  return (
    <ForceRegisterTabListWrapper
      eventId={event._id}
      slotIdx={slotIdx}
      type="users"
      registeredItems={slot.registeredUsers}
      isAddDisabled={isAddDisabled}
    >
      <SearchMenuMulti<IUserOption>
        name="forceRegisterAppointmentMultipleUsers"
        maxSelectedItems={nbOfUsersLeft}
        loadOptions={searchUsers}
        onSubmit={onSubmitHandler}
        components={{
          Option: UserOption,
          Value: UserItem,
        }}
        isLoading={isLoading}
        noOptionsMessage={onNoOptions}
        filterOption={filterOption}
        infiniteScroll={true}
      />
    </ForceRegisterTabListWrapper>
  );
}

export const ForceRegisterAppointmentMultipleUsers = React.memo(
  _ForceRegisterAppointmentMultipleUsers,
);

if (environment.__DEV__) {
  ForceRegisterAppointmentMultipleUsers.displayName = 'ForceRegisterAppointmentMultipleUsers';
}
