import { FormControl, FormErrorMessage, FormLabel, Stack } from '@chakra-ui/react';
import { EVENT_REGISTRATION_TYPES, EventEntry } from '@epitech/ops-panoramix-events-types';
import { GroupingRef } from '@epitech/ops-panoramix-types';
import { SingleValue } from 'chakra-react-select';
import React, { useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getGroupingsOptions } from '@/components/groups/select/helpers';
import {
  getActivitiesOptions,
  getModuleOption,
  IModuleOption,
  ModuleOption,
  ModuleValue,
  useModuleSearch,
} from '@/components/modules/select';
import { AsyncSelect } from '@/components/ui-elements/Select/Async';
import { Select, SelectMemo } from '@/components/ui-elements/Select/Select';
import { useLazyFetchModuleQuery } from '@/store/services/modules';

import { eventRegistrationTypeOptions } from './form.data';
import { getClonedModuleOption } from './helpers';

interface IGroupingOption {
  value: GroupingRef;
  label: string;
}

export const EventFormModule: React.FC = React.memo(() => {
  const { watch, formState, resetField, setValue } = useFormContext<EventEntry>();
  const [fetchModule, { data: module, isFetching: isModuleLoading }] = useLazyFetchModuleQuery();
  const searchModules = useModuleSearch(undefined, getClonedModuleOption);
  const { t } = useTranslation('components/event-form');
  const { t: tForm } = useTranslation('form_errors');
  const eventRegistrationTypeOpts = useMemo(() => eventRegistrationTypeOptions(), []);

  const { errors } = formState;

  const [moduleRef, registrationType] = watch(['moduleRef', 'registrationType']);
  const isModuleSynced = module && moduleRef && module._id === moduleRef._id;
  const prevModuleSelected = useRef(!moduleRef);

  const onModuleChange = useCallback(
    (newModuleRef: SingleValue<IModuleOption>) => {
      // If we don't reset the field, moduleRef.activityRef will persist even if `moduleRef` is set to null
      resetField('moduleRef.activityRef', { defaultValue: null });
      resetField('moduleRef.groupingsRef', { defaultValue: [] });
      resetField('cohortGroups', { defaultValue: [] });
      if (newModuleRef) {
        fetchModule(newModuleRef.value?._id)
          .unwrap()
          .then(module => {
            setValue('cohortGroups', module.cohortGroups);
          });
          if (prevModuleSelected.current) {
            setValue('registrationType', 'group');
          }
        } else {
        setValue('moduleRef', null);
        setValue('registrationType', 'individual');
      }
      prevModuleSelected.current = !newModuleRef?.value;
    },
    [fetchModule, resetField, setValue],
  );

  const groupingsOptions = useMemo(() => {
    if (moduleRef) {
      if (!module) {
        fetchModule(moduleRef?._id);
      } else {
        return getGroupingsOptions(module);
      }
    }
    return [];
  }, [module, moduleRef, fetchModule]);

  const activitiesOptions = useMemo(() => {
    if (moduleRef) {
      if (!module) {
        fetchModule(moduleRef?._id);
      } else {
        return getActivitiesOptions(module);
      }
    }
    return [];
  }, [module, moduleRef, fetchModule]);

  return (
    <>
      <FormControl isInvalid={errors.moduleRef !== undefined}>
        <FormLabel>{t('module')}</FormLabel>
        <AsyncSelect<IModuleOption, false>
          name="moduleRef"
          isLoading={isModuleLoading}
          isInvalid={errors.moduleRef !== undefined}
          onChange={onModuleChange}
          hasFormContext={true}
          noOptionsMessage={() => t('search_module_no_options')}
          loadOptions={searchModules}
          defaultOptions={moduleRef ? [getModuleOption(moduleRef)] : true}
          htmlDecode={true}
          isClearable={true}
          components={{ Option: ModuleOption, SingleValue: ModuleValue }}
          infiniteScroll={true}
        />
      </FormControl>
      {isModuleSynced && (
        <>
          <FormControl hidden={!module.activitiesRef || !module.activitiesRef.length}>
            <FormLabel>{t('activity')}</FormLabel>
            <SelectMemo
              key={moduleRef._id}
              isClearable
              name="moduleRef.activityRef"
              defaultValue={null}
              hasFormContext={true}
              options={activitiesOptions}
            />
          </FormControl>
          <Stack direction={['column', 'row']}>
            <FormControl flex={1}>
              <FormLabel>{t('registrationType')}</FormLabel>
              <SelectMemo
                name="registrationType"
                hasFormContext={true}
                options={eventRegistrationTypeOpts}
              />
              {errors.registrationType && errors.registrationType.message && (
                <FormErrorMessage>
                  {tForm(errors.registrationType.message, {
                    property: t('registrationType'),
                    types: EVENT_REGISTRATION_TYPES.join(' '),
                  })}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl flex={2} hidden={!groupingsOptions.length}>
              <FormLabel>{t('grouping_restriction')}</FormLabel>
              <Select<IGroupingOption, true>
                isClearable
                isMulti
                isDisabled={registrationType !== 'group'}
                name="moduleRef.groupingsRef"
                defaultValue={[]}
                hasFormContext={true}
                placeholder={t('grouping')}
                getOptionValue={option => option.value._id}
                options={groupingsOptions}
              />
            </FormControl>
          </Stack>
        </>
      )}
    </>
  );
});
