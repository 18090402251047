import { ButtonGroup, HStack, IconButton, Td, Text, Tooltip, Tr, useToast } from '@chakra-ui/react';
import type { EventAppointmentEntry } from '@epitech/ops-panoramix-events-types';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { GroupItem } from '@/components/groups';
import { TeamsButton } from '@/components/ui-elements/Buttons/TeamsButton';
import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';
import { BASE_OPACITY_TRANSITION } from '@/config/theme/components/others/UserItem';
import {
  useGetEventQuery,
  useRegisterToEventMutation,
  useUnregisterFromEventMutation,
} from '@/store/services/events';

import { EventSlotUsersList } from '../../../../UsersList';
import {
  ForceRegisterStaffsActionButton,
  RoomActionButton,
  StudentActionButton,
} from '../../Actions';
import { SlotRegisteredItemBaseProps } from '../types';
import { ForceRegisterAppointmentSingleActionButton } from './ForceRegisterActionButton';

export interface SlotRegisteredItemAppointmentSingleProps extends SlotRegisteredItemBaseProps {
  slot: EventAppointmentEntry['slots'][number];
}

function _SlotRegisteredItemAppointmentSingle({
  eventId,
  slot,
  slotIdx,
  allowRegistration,
  toRegister,
  isGroupMode,
  isAlreadyRegistered,
  canForceRegisterRooms = false,
  canForceRegisterUsers = false,
  canForceRegisterStaffs = false,
}: SlotRegisteredItemAppointmentSingleProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('components/events');
  const toast = useToast();
  const [registerToEvent, { isLoading: isRegisterLoading }] = useRegisterToEventMutation();
  const [unregisterFromEvent, { isLoading: isUnregisterLoading }] =
    useUnregisterFromEventMutation();

  const isLoading = isRegisterLoading || isUnregisterLoading;

  const onRegister = useCallback(async () => {
    if (!toRegister) {
      return;
    }
    /** We can be sure that `toRegister` will be defined at the moment of the call from {@link SlotItem['getActionButtons']} */
    await registerToEvent({
      eventId,
      toRegister,
      type: isGroupMode ? 'projectGroups' : 'users',
      slotIdx: slotIdx,
    }).unwrap();

    toast({
      description: t('success_inscription'),
      status: 'success',
      position: 'top',
    });
  }, [eventId, toRegister, registerToEvent, toast, t, isGroupMode, slotIdx]);

  const onUnregister = useCallback(async () => {
    if (!toRegister) {
      return;
    }
    await unregisterFromEvent({
      eventId,
      type: isGroupMode ? 'projectGroups' : 'users',
      toRegister: toRegister,
      slotIdx: slotIdx,
    }).unwrap();

    toast({
      description: t('success_desinscription'),
      status: 'success',
      position: 'top',
    });
  }, [eventId, toRegister, unregisterFromEvent, toast, isGroupMode, t, slotIdx]);

  const renderRegisteredItem = useMemo(() => {
    if (slot.registeredUser) {
      return <UserItem width={'80%'} value={slot.registeredUser} fontSize="md" />;
    }
    if (slot.registeredGroup) {
      return <GroupItem width={'80%'} value={slot.registeredGroup} fontSize="md" />;
    }
    return <Text lineHeight="base">{t('no_participant')}</Text>;
  }, [slot.registeredUser, slot.registeredGroup, t]);

  const openFollowup = useCallback(
    (slotIdx: number) => {
      navigate({
        pathname: `/calendar/followup/${eventId}/${slotIdx}`,
        search: location.search,
      });
    },
    [eventId, location.search, navigate],
  );

  const dateFormat = t('date_format');
  const hourFormat = t('hour_format');
  const isSlotFull = !!(slot.registeredUser || slot.registeredGroup);

  const { data: event } = useGetEventQuery(eventId);

  const isFollowUpEvent = event?.moduleRef?.activityRef?.tpIds
    ? event.moduleRef.activityRef.tpIds.some(tpId => tpId.sourceType === 'followup')
    : false;

  const isUserSlot = isGroupMode
    ? slot.registeredGroup?._id === toRegister?._id
    : slot.registeredUser?._id === toRegister?._id;

  return (
    <Tr role="group">
      <Td fontFamily="mono">{format(slot.start, dateFormat)}</Td>
      <Td fontFamily="mono">
        {format(slot.start, hourFormat)} - {format(slot.end, hourFormat)}
      </Td>
      <Td>
        <HStack justify="space-between">
          <EventSlotUsersList
            users={slot.registeredStaffs}
            emptyLabel={t('no_staff')}
            moreLabel={t('more_staffs')}
          />
          {canForceRegisterStaffs && (
            <ForceRegisterStaffsActionButton eventId={eventId} slot={slot} slotIdx={slotIdx} />
          )}
        </HStack>
      </Td>
      <Td>
        <HStack justify="space-between" gap={2}>
          {renderRegisteredItem}
          {canForceRegisterUsers ? (
            <ButtonGroup
              size="xs"
              opacity={0}
              transition={BASE_OPACITY_TRANSITION}
              _groupHover={{
                opacity: 1,
              }}
            >
              <TeamsButton
                users={
                  slot.registeredUser
                    ? [slot.registeredUser?.login]
                    : slot.registeredGroup?.usersRef.map(el => el.login)
                }
              />
              {slot.registeredGroup && isFollowUpEvent && (
                <Tooltip label={t('grade_followup')}>
                  <IconButton
                    onClick={() => openFollowup(slotIdx)}
                    aria-label={t('grade_followup')}
                    icon={<FiEdit />}
                  />
                </Tooltip>
              )}
              <ForceRegisterAppointmentSingleActionButton
                userType={isGroupMode ? 'groups' : 'users'}
                eventId={eventId}
                slot={slot}
                slotIdx={slotIdx}
                isLoading={isLoading}
                isSlotFull={isSlotFull}
              />
            </ButtonGroup>
          ) : toRegister && ((!isAlreadyRegistered && !isSlotFull) || isUserSlot) ? (
            <StudentActionButton
              isPast={isPast(slot.start)}
              isUserSlot={isUserSlot}
              isGroupMode={isGroupMode}
              toRegister={toRegister}
              isLoading={isLoading}
              allowRegistration={allowRegistration && !isSlotFull}
              onRegister={onRegister}
              onUnregister={onUnregister}
            />
          ) : null}
        </HStack>
      </Td>
      <Td>
        <HStack justify="space-between">
          {slot?.roomRef ? (
            <Text
              title={`${slot.roomRef.name}`}
              width={'80%'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >{`${slot.roomRef.name}`}</Text>
          ) : (
            <Text>{t('no_room')}</Text>
          )}
          {canForceRegisterRooms && (
            <RoomActionButton
              eventId={eventId}
              slot={slot}
              slotIdx={slotIdx}
              isSlotFull={!!slot?.roomRef}
              size="xs"
              opacity={0}
              transition={BASE_OPACITY_TRANSITION}
              _groupHover={{
                opacity: 1,
              }}
            />
          )}
        </HStack>
      </Td>
    </Tr>
  );
}

export const SlotRegisteredItemAppointmentSingle = React.memo(_SlotRegisteredItemAppointmentSingle);

if (environment.__DEV__) {
  SlotRegisteredItemAppointmentSingle.displayName = 'SlotRegisteredItemAppointmentSingle';
}
