import { Tag, TagLeftIcon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiAlertTriangle } from "react-icons/fi";

function _EventRoomsDeprecationWarning() {
  const { t } = useTranslation('layouts/calendar/Option');

  return (
    <Tooltip aria-label={t("deprecated")} label={t("deprecated")}>
      <Tag pr={0} ml={2} variant="subtle" colorScheme="orange" size="sm">
        <TagLeftIcon boxSize="12px" as={FiAlertTriangle} />
      </Tag>
    </Tooltip>
  );
}

export const EventRoomsDeprecationWarning = React.memo(_EventRoomsDeprecationWarning);
