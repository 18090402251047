import { IconButton, Link, Tooltip } from '@chakra-ui/react';
import type { ProjectGroupRef } from '@epitech/ops-panoramix-types';
import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';

import { getMoodleLinkToActivity } from '@/lib/helpers/utils';
import { useGetEventQuery } from '@/store/services/events';

interface GoToExternalGroupSourceProps {
  eventId: string;
  registeredGroup?: ProjectGroupRef;
}

function GoToExternalGroupSource({ eventId, registeredGroup }: GoToExternalGroupSourceProps) {
  const { data: event } = useGetEventQuery(eventId);
  const { t } = useTranslation('components/events');

  const moduleRef = event?.moduleRef;
  const moduleSource = moduleRef?.tpIds[0].source;
  const label = t('go_to_group_source', { source: capitalize(moduleSource) });

  const externalActivityGroupLink = useMemo(() => {
    if (moduleRef?.activityRef && registeredGroup && moduleSource) {
      return getMoodleLinkToActivity(moduleSource, moduleRef.activityRef, registeredGroup);
    }
  }, [moduleSource, moduleRef?.activityRef, registeredGroup]);

  if (!externalActivityGroupLink) {
    return null;
  }

  return (
    <Link isExternal href={externalActivityGroupLink}>
      <Tooltip label={label}>
        <IconButton variant="ghost" aria-label={label} icon={<FiExternalLink />} />
      </Tooltip>
    </Link>
  );
}

export default React.memo(GoToExternalGroupSource);
