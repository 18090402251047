import parseISO from 'date-fns/parseISO';

import type { RawDateObject, SanitizedDates } from '../types/events';
import { WithRawDates } from '../types/events';

export function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false;
  }
  const d = new Date(str);
  return !isNaN(d.getTime()) && d.toISOString() === str;
}

export function sanitizeDatePayload<T>(payload: WithRawDates<T>): T {
  if (typeof payload === 'string') {
    if (isIsoDate(payload)) {
      return parseISO(payload) as unknown as T;
    }
    return payload as unknown as T;
  }

  if (Array.isArray(payload)) {
    return payload.map(item => sanitizeDatePayload(item)) as unknown as T;
  }

  if (payload && typeof payload === 'object') {
    const acc = {} as Record<string, unknown>;
    Object.keys(payload).forEach(key => {
      acc[key] = sanitizeDatePayload((payload as Record<string, unknown>)[key]);
    });
    return acc as T;
  }

  return payload as T;
}

/**
 *   Will cast any object with a `start` and `end` field to a DateObject
 */
export const sanitizeDateObject = <K extends RawDateObject>(dateObject: K): SanitizedDates<K> => {
  const { start, end } = dateObject;
  return { ...dateObject, start: parseISO(start), end: parseISO(end) };
};
