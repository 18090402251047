import {
  Alert,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { type FallbackRender, showReportDialog } from '@sentry/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText, FiLogOut, FiRotateCcw, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import LabelledContainer from '@/components/ui-elements/LabelledContainer';
import { useAuth } from '@/lib/hooks/useAuth';
import useLogout from '@/lib/hooks/useLogout';

import DebugPayload from './DebugPayload';
import { resetStorage } from './helpers';

const ProviderFallback: FallbackRender = ({
  componentStack,
  error: rawError,
  eventId,
  resetError,
}) => {
  const error = rawError as Error;
  const { t } = useTranslation(['error-page', 'actions']);
  const navigate = useNavigate();

  const { user } = useAuth();
  const { handleLogout } = useLogout();

  const hardReset = useCallback(() => {
    handleLogout([resetStorage, resetError]);
  }, [handleLogout, resetError]);

  const onClose = useCallback(() => {
    resetError();
    navigate({
      pathname: '/calendar',
    });
  }, [resetError, navigate]);

  return (
    <Modal isOpen={true} onClose={onClose} size="2xl" trapFocus={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{t('error_modal_label')}</Text>
        </ModalHeader>
        <ModalBody as={VStack} gap={4} alignItems="stretch">
          {user && <LabelledContainer label={t('logged_as')}>{user.login}</LabelledContainer>}
          <DebugPayload
            componentStack={componentStack}
            error={error?.toString()}
            eventId={eventId}
          />
        </ModalBody>
        <ModalFooter as={VStack} gap={4}>
          <Alert status="warning" display="flex" justifyContent="space-between" alignItems="center">
            <Text fontSize="sm" fontWeight="semibold" fontFamily="mono">
              {t('error_modal_warning_cache')}
            </Text>
            <Button
              flexShrink={0}
              size="sm"
              rightIcon={<FiRotateCcw />}
              textStyle="muted-header"
              onClick={hardReset}
              variant="outline"
              borderColor="primaryFontColor"
            >
              {t('actions:action_reset')}
            </Button>
          </Alert>
          <ButtonGroup alignSelf="end" isAttached={true} colorScheme="white">
            <Button colorScheme="red" onClick={() => handleLogout()} leftIcon={<FiLogOut />}>
              {t('actions:action_disconnect')}
            </Button>
            <Button leftIcon={<FiFileText />} onClick={showReportDialog}>
              {t('actions:action_report')}
            </Button>
            <Button onClick={onClose} leftIcon={<FiX />}>
              {t('actions:action_close')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProviderFallback;
