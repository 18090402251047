import { useToast } from '@chakra-ui/react';
import type { StoredEventEntry } from '@epitech/ops-panoramix-events-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getRoomOption, type IRoomOption, RoomOption, RoomValue } from '@/components/rooms/select';
import { SearchMenu } from '@/components/ui-elements/SearchMenu/Single';
import { environment } from '@/config/environment';
import { useForceRegisterRoomsToEventMutation } from '@/store/services/events';

type ForceRegisterRoomsProps = {
  event: StoredEventEntry;
  slotIdx?: number;
  onClose: () => void;
};

function _ForceRegisterRooms({ event, onClose, slotIdx }: ForceRegisterRoomsProps) {
  const [forceRegisterRoomToEvent, { isLoading: isRegisterLoading }] =
    useForceRegisterRoomsToEventMutation();
  const { t } = useTranslation('components/events');
  const toast = useToast();

  const onRoomSearch = useCallback(async () => {
    const result = event.roomsRef;
    return result.map(getRoomOption);
  }, [event.roomsRef]);

  const onSubmitHandler = useCallback(
    async (selectedOptions: IRoomOption['value']) => {
      if (selectedOptions) {
        await forceRegisterRoomToEvent({
          eventId: event._id!,
          roomsToRegister: [selectedOptions],
          slotIdx,
        }).unwrap();
        toast({
          description: t('room_register_success'),
          status: 'success',
          position: 'top',
        });
        onClose();
      }
    },
    [onClose, event._id, slotIdx, forceRegisterRoomToEvent, t, toast],
  );

  const onNoOptions = useCallback(() => t('empty_room-search'), [t]);

  return (
    <SearchMenu<IRoomOption>
      name="forceRegisterRoom"
      loadOptions={onRoomSearch}
      onSubmit={onSubmitHandler}
      components={{
        Option: RoomOption,
        SingleValue: RoomValue,
      }}
      isLoading={isRegisterLoading}
      noOptionsMessage={onNoOptions}
    />
  );
}

export const ForceRegisterRooms = React.memo(_ForceRegisterRooms);

if (environment.__DEV__) {
  ForceRegisterRooms.displayName = 'ForceRegisterRooms';
}
