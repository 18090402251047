import { Td, Text, Tr } from '@chakra-ui/react';
import {
  type EventSlotMultipleEntry,
  type EventSlotSingleEntry,
} from '@epitech/ops-panoramix-events-types';
import format from 'date-fns/format';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupItem } from '@/components/groups';
import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';

import { EventSlotGroupsList } from '../GroupsList';
import { EventSlotUsersList } from '../UsersList';

interface ISlotProps {
  slot: EventSlotSingleEntry | EventSlotMultipleEntry;
}

function _SlotPreviewItem({ slot }: ISlotProps) {
  const { t } = useTranslation('components/events');

  const renderParticipants = useMemo(() => {
    switch (slot.type) {
      case 'slot':
        if (slot.registeredUser) {
          return <UserItem value={slot.registeredUser} />;
        }
        if (slot.registeredGroup) {
          return <GroupItem justifyContent="start" value={slot.registeredGroup} />;
        }
        return <Text lineHeight="base">{t('no_participant')}</Text>;
      case 'slot_multiple':
        if (slot.registeredUsers?.length) {
          return (
            <EventSlotUsersList
              users={slot.registeredUsers}
              emptyLabel={t('no_participant')}
              moreLabel={t('more_participants')}
              onDisplayLength={2}
            />
          );
        }
        if (slot.registeredGroups?.length) {
          return <EventSlotGroupsList groups={slot.registeredGroups} onDisplayLength={1} />;
        }
        return <Text lineHeight="base">{t('no_participant')}</Text>;
      default:
        return null;
    }
  }, [slot, t]);

  const dateFormat = t('date_format') === 'date_format' ? 'MM/dd' : t('date_format');
  const hourFormat = t('hour_format') === 'hour_format' ? 'h:mmaaa' : t('hour_format');

  return (
    <Tr role="group">
      <Td fontFamily="mono">{format(slot.start, dateFormat)}</Td>
      <Td fontFamily="mono">
        {format(slot.start, hourFormat)} - {format(slot.end, hourFormat)}
      </Td>
      <Td>
        <EventSlotUsersList
          users={slot.registeredStaffs}
          emptyLabel={t('no_staff')}
          moreLabel={t('more_staffs')}
          onDisplayLength={1}
        />
      </Td>
      <Td>{renderParticipants}</Td>
      <Td>
        {slot?.roomRef ? (
          <Text
            title={`${slot.roomRef.name}`}
            width={'80%'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
          >{`${slot.roomRef.name}`}</Text>
        ) : (
          <Text>{t('no_room')}</Text>
        )}
      </Td>
    </Tr>
  );
}

export const SlotPreviewItem = React.memo(_SlotPreviewItem);

if (environment.__DEV__) {
  SlotPreviewItem.displayName = 'SlotPreviewItem';
}
