import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import type { EventWarningPayload, WarningError } from './types';

export function isWarningPayload(value: unknown): value is EventWarningPayload {
  return (
    !!value &&
    typeof value === 'object' &&
    ('deletedSlots' in value || 'eventsConflicting' in value || 'unregisteredByEdit' in value)
  );
}

export function isWarningError(
  error?: FetchBaseQueryError | SerializedError,
): error is WarningError {
  return !!error && 'status' in error && error.status === 400 && isWarningPayload(error.data);
}
