import { HStack, List, ListItem, StackDivider, Text, VStack } from '@chakra-ui/react';
import { ProjectGroupRef, UserRef } from '@epitech/ops-panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RegisteredGroupItem, RegisteredStaffItem, RegisteredUserItem } from '../registered';

type CoursePreviewListProps = {
  registered: UserRef[] | ProjectGroupRef[];
  registeredStaffs: UserRef[];
  isGroupMode: boolean;
};

function _CoursePreviewList({
  registered,
  registeredStaffs,
  isGroupMode = false,
}: CoursePreviewListProps) {
  const { t } = useTranslation('components/events');

  return (
    <HStack
      alignItems="start"
      w="full"
      divider={<StackDivider />}
      layerStyle="container"
      px={4}
      py={2}
      gap={2}
    >
      <VStack alignItems="stretch">
        <Text textAlign="left" textStyle="muted-header">
          {t('staff_title')}
        </Text>
        <List variant="bordered">
          {registeredStaffs && registeredStaffs.length > 0
            ? registeredStaffs.map(registeredItem => (
                <RegisteredStaffItem
                  key={registeredItem._id}
                  canForceUnregister={false}
                  user={registeredItem}
                  eventId={''}
                />
              ))
            : null}
        </List>
      </VStack>
      <VStack flex={1} alignItems="stretch">
        <Text textAlign="left" textStyle="muted-header">
          {isGroupMode ? t('group_title') : t('student_title')}
        </Text>
        <List variant="bordered">
          {registered.map(registeredItem => {
            if ('usersRef' in registeredItem) {
              return (
                <RegisteredGroupItem
                  as={ListItem}
                  key={registeredItem._id}
                  group={registeredItem}
                  eventId=""
                />
              );
            }
            return (
              <RegisteredUserItem
                as={ListItem}
                key={registeredItem._id}
                user={registeredItem}
                eventId=""
              />
            );
          })}
        </List>
      </VStack>
    </HStack>
  );
}

export const CoursePreviewList = React.memo(_CoursePreviewList);
