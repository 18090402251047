import { CohortGroup, CohortRef } from '@epitech/ops-panoramix-types';

import { IOptionGen } from '@/components/ui-elements/Select/types';

export function findCohortFromType(cgs: CohortGroup[] | undefined, type: keyof CohortGroup) {
  if (!cgs) return [];

  const options: IOptionGen<CohortRef>[] = [];
  cgs.forEach(cg => {
    const value = cg[type];
    if (value && !options.find(c => c.value._id === value._id)) {
      options.push({ value: value, label: value.name });
    }
  });
  return options;
}

export function getCohortGroupMatcher(cohortGroups: CohortGroup[]) {
  return (
    cohortType: keyof CohortGroup,
    cohortOptions: IOptionGen<CohortRef>[],
    cohortGroupToMatch: Partial<CohortGroup>,
  ) => {
    if (Object.values(cohortGroupToMatch).every(cr => cr === undefined)) {
      return cohortOptions;
    }

    const cohortGroupToMatchEntries = Object.entries(cohortGroupToMatch);
    const cohortGroupsWithCohortType = cohortGroups.filter(cg => {
      return cohortGroupToMatchEntries.every(([key, value]) => {
        return !value || cg[key as keyof CohortGroup]?._id === value._id;
      });
    });

    return cohortOptions.filter(c => {
      return cohortGroupsWithCohortType.some(cg => cg[cohortType]?._id === c.value._id);
    });
  };
}
