import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronRight, FiMenu } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

import { environment } from '@/config/environment';
import { getSearchParams } from '@/lib/helpers/utils';
import { useAuth } from '@/lib/hooks/useAuth';
import { useRoutingPath } from '@/lib/hooks/useRoutingPath';

import { ProfileMenu } from './users/ProfileMenu';

function _Header() {
  const { user, logasUser } = useAuth();
  const { t } = useTranslation('layouts/header');
  const pages = useRoutingPath();
  const location = useLocation();

  if (!user) {
    return null;
  }

  return (
    <Box
      as="header"
      w="full"
      borderRadius="md"
      shadow="md"
      bg="thirdBgColor"
      color="primaryFontColor"
    >
      {logasUser && (
        <Alert status="warning" w="full" justifyContent="center" borderTopRadius="md">
          <AlertIcon />
          {t('logged_as')}&nbsp;
          <Text fontWeight="bold" textTransform="capitalize">
            {user.firstName} {user.lastName}
          </Text>
        </Alert>
      )}
      <Flex justify="center" align="center" h="60px" px={4}>
        <Image src={`/logos/${environment.LOGO_PATH}.png`} w="32px" h="32px" mr={4} />
        <Heading size="md">
          {environment.NAME}&nbsp;
          <Text color="secondFontColor" fontSize={14}>
            {t('title')}
          </Text>
        </Heading>
        <Spacer />
          <Menu placement="bottom-end" closeOnSelect={true} isLazy>
            <MenuButton
              as={IconButton}
              icon={<FiMenu />}
              mr={3}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="1px"
              variant={'ghost'}
            />
            <MenuList>
              <MenuOptionGroup type="radio" defaultValue={location.pathname.replace('/', '')}>
                {pages.map(page => {
                  const params = getSearchParams(page);
                  return (
                    <MenuItemOption
                      as={Link}
                      key={page}
                      value={page}
                      icon={<FiChevronRight />}
                      _checked={{ backgroundColor: 'menuSelectedBgColor' }}
                      to={{pathname: page, search: params.toString()}}
                    >
                      {t(page)}
                    </MenuItemOption>
                  );
                })}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        <ProfileMenu user={user} />
      </Flex>
    </Box>
  );
}

export const Header = React.memo(_Header);
