import {
  EventSlotMultipleEntry,
  EventSlotSingleEntry,
  getSlotInput,
  IEventAppointmentMultipleStudentEntry,
  IEventAppointmentStudentEntry,
} from '@epitech/ops-panoramix-events-types';
import React, { useMemo } from 'react';

import { environment } from '@/config/environment';
import { sanitizeDatePayload } from '@/lib/helpers/dates.helpers';
import { splitSlotsByConcurrency } from '@/lib/helpers/events.helpers';
import { WithRawDates } from '@/lib/types/events';
import { useGetEventQuery } from '@/store/services/events';

import { useEventDetailsContext } from '../../detailsProvider/useEventDetailsContext';
import { SlotRegisteredOccurenceGroup } from './RegisteredOccurenceGroup';

const emptyArray: WithRawDates<EventSlotSingleEntry>[] | WithRawDates<EventSlotMultipleEntry>[] =
  [];

function _SlotRegisteredList() {
  const { eventId } = useEventDetailsContext();
  const { slots: rawSlots } = useGetEventQuery(eventId, {
    selectFromResult: ({ data }) => ({
      slots: data?.type !== 'course' ? data?.slots ?? emptyArray : emptyArray,
      type: data?.type,
    }),
  });

  const slots = useMemo(
    () => sanitizeDatePayload<EventSlotSingleEntry[] | EventSlotMultipleEntry[]>(rawSlots),
    [rawSlots],
  );

  const slotInputData = useMemo(() => (slots.length ? getSlotInput(slots) : null), [slots]);

  const slotSplitted: ((
    | IEventAppointmentStudentEntry['slots'][number]
    | IEventAppointmentMultipleStudentEntry['slots'][number]
  ) & { oldIndex?: number })[][] = useMemo(() => {
    if (!slotInputData?.occurence || !slotInputData?.occurence) {
      return [slots];
    }
    return splitSlotsByConcurrency<
      | IEventAppointmentStudentEntry['slots'][number]
      | IEventAppointmentMultipleStudentEntry['slots'][number]
    >(slots, slotInputData.occurence);
  }, [slots, slotInputData?.occurence]);

  return slotSplitted.map((slots, slotGroupindex) => (
    <SlotRegisteredOccurenceGroup key={slotGroupindex} slots={slots} />
  ));
}

export const SlotRegisteredList = React.memo(_SlotRegisteredList);

if (environment.__DEV__) {
  SlotRegisteredList.displayName = 'SlotRegisteredList';
}
