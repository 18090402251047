import 'react-datepicker/dist/react-datepicker.min.css';

import { chakra, Input, InputProps } from '@chakra-ui/react';
import set from 'date-fns/set';
import React, { useCallback, useEffect } from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getDateFnsLanguage } from '@/config/localization';
import type { PartialBy } from '@/lib/types/utils';

type DatePickerProps = PartialBy<ReactDatePickerProps, 'onChange'> & {
  size?: InputProps['size'];
  isInvalid?: boolean;
  defaultValue?: Date;
  name: string;
  timeDate?: Date;
};

const _DatePicker: React.FC<DatePickerProps> = ({
  isClearable = false,
  showPopperArrow = false,
  defaultValue = new Date(),
  name,
  onChange,
  size,
  isInvalid = false,
  timeDate,
  className,
  ...props
}) => {
  const { control } = useFormContext();

  const {
    field: { ref: fieldRef, value, onChange: onControllerChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const onInternalChange: ReactDatePickerProps['onChange'] = (date, e): void => {
    if (timeDate && date) {
      date = set(timeDate, {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      });
    }
    onControllerChange(date);
    if (onChange) onChange(date, e);
  };

  const { t } = useTranslation('components/shared');

  const initLanguage = useCallback((languageStr: string) => {
    const language = getDateFnsLanguage(languageStr);
    registerLocale(languageStr, language);
  }, []);

  useEffect(() => initLanguage(t('localeDateFns')), [t, initLanguage]);

  initLanguage(t('localeDateFns'));

  return (
    <ReactDatePicker
      isClearable={isClearable}
      showPopperArrow={showPopperArrow}
      dateFormat={t('date')}
      timeFormat={t('timeFormat')}
      timeCaption={t('time')}
      locale={t('localeDateFns')}
      selected={value}
      enableTabLoop={false}
      ref={ref => {
        fieldRef({ focus: ref?.setFocus });
      }}
      customInput={
        <Input
          fontFamily="mono"
          w="full"
          variant="white"
          size={size}
          isInvalid={isInvalid}
          {...inputProps}
          _invalid={{
            borderColor: 'warningBorderColor',
          }}
        />
      }
      {...props}
      onChange={onInternalChange}
      wrapperClassName={className}
    />
  );
};

export const DatePicker = React.memo(chakra(_DatePicker));
