import {
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { FiX } from 'react-icons/fi';

import { PopoverPortal } from '../PopoverPortal';
import { ConfirmPopoverContent, IConfirmPopoverContentProps } from './Content';

interface IConfirmPopoverProps extends IConfirmPopoverContentProps, PopoverProps {
  hasPortal?: boolean;
  renderTrigger?: () => React.ReactElement;
}

function _ConfirmPopover({
  renderTrigger,
  confirmLabel,
  onConfirm,
  resourceName,
  isLoading = false,
  hasPortal = true,
  confirmButtonLabel,
  ...popoverProps
}: IConfirmPopoverProps) {
  const Wrapper = hasPortal ? PopoverPortal : React.Fragment;

  return (
    <Popover isLazy={true} {...popoverProps} lazyBehavior="unmount">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {renderTrigger ? (
              renderTrigger()
            ) : (
              <IconButton icon={<Icon as={FiX} />} aria-label="confirm" />
            )}
          </PopoverTrigger>
          <Wrapper>
            <PopoverContent w="auto" fontSize="sm">
              <ConfirmPopoverContent
                confirmLabel={confirmLabel}
                confirmButtonLabel={confirmButtonLabel}
                isLoading={isLoading}
                resourceName={resourceName}
                onConfirm={onConfirm}
                onClose={onClose}
              />
            </PopoverContent>
          </Wrapper>
        </>
      )}
    </Popover>
  );
}

export const ConfirmPopover = React.memo(_ConfirmPopover);
