import { HStack, Icon, IconButton, StackProps } from '@chakra-ui/react';
import { UserRef } from '@epitech/ops-panoramix-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

import { RegisteredItemProps } from '@/components/events/registered/types';
import { ConfirmPopover } from '@/components/ui-elements/ConfirmPopover';
import { UserItem } from '@/components/users/UserItem';
import { BASE_OPACITY_TRANSITION } from '@/config/theme';
import { useEventUnregistration } from '@/lib/hooks/useEventUnregistration';

interface RegisteredUserItemProps extends RegisteredItemProps {
  user: UserRef;
}

function _RegisteredUserItem({
  user: userRef,
  eventId,
  canUnregister,
  canForceUnregister,
  slotIdx,
  hasPortal = true,
  unregisterBtnOnHover: _unregisterBtnOnHover,
  ...stackProps
}: RegisteredUserItemProps & StackProps): React.ReactElement {
  const { t } = useTranslation('components/events');
  const [unregisterFromEvent, { isLoading: isUnregisterLoading }] = useEventUnregistration({
    eventId,
    type: 'users',
    canForceRegister: canForceUnregister,
    slotIdx,
    toRegister: userRef,
  });

  const unregisterBtnOnHover =
    _unregisterBtnOnHover !== undefined
      ? _unregisterBtnOnHover
      : !canForceUnregister && !canUnregister;

  const btnProps = useMemo(
    () =>
      unregisterBtnOnHover
        ? {
            opacity: 0,
            transition: BASE_OPACITY_TRANSITION,
            _groupHover: {
              opacity: 1,
            },
          }
        : {},
    [unregisterBtnOnHover],
  );

  const deleteButton = useMemo(
    () =>
      canUnregister || canForceUnregister ? (
        <ConfirmPopover
          onConfirm={unregisterFromEvent}
          isLoading={isUnregisterLoading}
          hasPortal={hasPortal}
          confirmLabel={t('unregister_confirm')}
          confirmButtonLabel={t('unregister_btn_label')}
          resourceName={userRef.firstName}
          renderTrigger={() => (
            <IconButton
              icon={<Icon as={FiX} />}
              colorScheme="red"
              variant="ghost"
              aria-label="unregister"
              size="xs"
              ml="auto"
              {...btnProps}
            />
          )}
        />
      ) : null,
    [
      hasPortal,
      isUnregisterLoading,
      userRef.firstName,
      t,
      btnProps,
      canUnregister,
      canForceUnregister,
      unregisterFromEvent,
    ],
  );
  return (
    <HStack
      display="flex"
      flexDir="row"
      alignItems="center"
      w="full"
      role="group"
      gap={2}
      {...stackProps}
    >
      <UserItem value={userRef} width="max-content" flexGrow={1} />
      {deleteButton}
      {/* <Text fontStyle="italic" color="gray.500" textTransform="capitalize" width="min-content">
        {attendance}
      </Text> */}
    </HStack>
  );
}

export const RegisteredUserItem = React.memo(_RegisteredUserItem);
