import { FormControl, FormLabel } from '@chakra-ui/react';
import { RoomRef } from '@epitech/ops-panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IRoomOption } from '@/components/rooms/select/helpers';
import { RoomOptionMultiValue } from '@/components/rooms/select/Option';
import { RoomMultiValue } from '@/components/rooms/select/Value';
import { getRoomOption, useRoomSearch } from '@/components/rooms/useRoomSearch';
import { AsyncSelect } from '@/components/ui-elements/Select/Async';

interface IEventFormRoomProps {
  defaultRoomsRef?: RoomRef[];
}

function _EventFormRoom({ defaultRoomsRef = [] }: IEventFormRoomProps): React.ReactElement {
  const searchRooms = useRoomSearch();

  const { t } = useTranslation('components/event-form');

  return (
    <FormControl flex={2}>
      <FormLabel>{t('room')}</FormLabel>
      <AsyncSelect<IRoomOption, true>
        name="roomsRef"
        isClearable
        isMulti
        defaultOptions={defaultRoomsRef.length ? defaultRoomsRef.map(getRoomOption) : true}
        hasFormContext={true}
        loadOptions={searchRooms}
        getOptionValue={option => option.value._id}
        noOptionsMessage={() => t('search_room_no_options')}
        components={{ Option: RoomOptionMultiValue, MultiValue: RoomMultiValue }}
        infiniteScroll={true}
      />
    </FormControl>
  );
}

export const EventFormRoom = React.memo(_EventFormRoom);
