import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchMenuMulti } from '@/components/ui-elements/SearchMenu/Multi';
import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { getStaffOption, type IUserOption, UserOption } from '@/components/users/select';
import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';
import { ISearchStaffsRequest, useLazySearchStaffsQuery } from '@/store/services/users';

type ForceRegisterStaffsBaseProps = {
  searchParams: Partial<ISearchStaffsRequest>;
  filterOption: (option: FilterOptionOption<IUserOption>) => boolean;
  onSubmit: (selectedOptions: IUserOption[]) => void;
  isLoading?: boolean;
};

function _ForceRegisterStaffsBase({
  onSubmit,
  searchParams,
  filterOption,
  isLoading = false,
}: ForceRegisterStaffsBaseProps) {
  const { t } = useTranslation('components/events');
  const [searchStaffs] = useLazySearchStaffsQuery();

  const onStaffSearch = useCallback(
    async (
      search: string,
      _callback?: (options: IUserOption[]) => void | Promise<IUserOption[]>,
      limit?: number,
      offset?: number,
    ) => {
      const result = await searchStaffs(
        {
          search,
          ...searchParams,
          limit,
          offset,
        },
        true,
      ).unwrap();
      return result.map(getStaffOption);
    },
    [searchParams, searchStaffs],
  );

  const onNoOptions = useCallback(() => t('empty_user-search'), [t]);

  return (
    <SearchMenuMulti<IUserOption>
      name="forceRegisterStaffs"
      loadOptions={onStaffSearch}
      onSubmit={onSubmit}
      components={{
        Option: UserOption,
        Value: UserItem,
      }}
      isLoading={isLoading}
      noOptionsMessage={onNoOptions}
      filterOption={filterOption}
      infiniteScroll={true}
    />
  );
}

export const ForceRegisterStaffsBase = React.memo(_ForceRegisterStaffsBase);

if (environment.__DEV__) {
  ForceRegisterStaffsBase.displayName = 'ForceRegisterStaffsBase';
}
