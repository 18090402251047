import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

export type FormNumberInputProps = NumberInputProps & {
  name: string;
  onInputChange?: (value: number | '') => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: ControllerProps<any>['control'];
  hasStepper?: boolean;
};

/** An implementation of `Chakra@NumberInput` with react-hook-form. Currently using `register` directly on the `NumberInput` component
 * cause either accessibility or typescript errors.
 */

function _FormNumberInput({
  control,
  onInputChange,
  hasStepper,
  ...inputProps
}: FormNumberInputProps) {
  return (
    <Controller
      control={control}
      name={inputProps.name}
      defaultValue={inputProps.defaultValue}
      render={({ field: { ref, onChange: onChangeField, ...restField } }) => (
        /** `register({ valueAsNumber: true })` is not available so we need to parse the value as an integer */
        <NumberInput
          {...inputProps}
          {...restField}
          onChange={v => {
            const parsedValue = parseInt(v, 10);
            const res = isNaN(parsedValue) ? '' : parsedValue;

            if (onInputChange) {
              onInputChange(res);
            }
            return onChangeField(res);
          }}
        >
          <NumberInputField ref={ref} name={restField.name} />
          {hasStepper !== undefined && (
            <>
              <NumberInputStepper borderEndRadius={7}>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </>
          )}
        </NumberInput>
      )}
    />
  );
}

export const FormNumberInput = React.memo(_FormNumberInput);
