import { HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormNumberInput, FormNumberInputProps } from './FormNumberInput';

/** An implementation of `Chakra@NumberInput` with react-hook-form. Currently using `register` directly on the `NumberInput` component
 * cause either accessibility or typescript errors.
 */

function _FormNumberSliderInput(props: FormNumberInputProps) {
  const { setValue, watch } = useFormContext();

  const value = watch(props.name);

  return (
    <HStack>
      <FormNumberInput {...props} />
      <Slider
        flex="1"
        min={props.min}
        max={props.max}
        step={props.step}
        ml={3}
        w={100}
        focusThumbOnChange={false}
        onChange={v => setValue(props.name, v)}
        color="black"
        value={value}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb fontSize="sm" boxSize={8} children={value} />
      </Slider>
    </HStack>
  );
}

export const FormNumberSliderInput = React.memo(_FormNumberSliderInput);
