import { Flex, Icon, IconButton, Stack } from '@chakra-ui/react';
import type { MultiValue } from 'chakra-react-select';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiTrash } from 'react-icons/fi';

import { IBaseItemOption } from '@/components/types';

import { AsyncSelect } from '../../Select/Async';
import { FilterOptionOption } from '../../Select/types';
import { SearchMenuProps } from '../types';
import { SearchMenuSelectedList } from './List';

export function SearchMenuMulti<T extends IBaseItemOption>({
  onSubmit,
  loadOptions,
  components,
  filterOption,
  isLoading = false,
  maxSelectedItems,
  ...selectProps
}: SearchMenuProps<T, true>): React.ReactElement {
  const [selected, setSelected] = React.useState<MultiValue<T>>([]);
  const isEmpty = selected.length === 0;
  const { t } = useTranslation('components/shared');
  const { Option, Value } = components;
  const isAddDisabled = selected.length >= (maxSelectedItems ?? Infinity);

  const onChangeHandler = useCallback(
    (_selectedOptions: MultiValue<T>) => setSelected(_selectedOptions),
    [setSelected],
  );

  const onDeleteHandler = useCallback(
    (value: T['value']) => {
      setSelected(currentSelected => currentSelected.filter(it => it.value._id !== value._id));
    },
    [setSelected],
  );

  const onClearHandler = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  const onSubmitHandler: React.FormEventHandler<HTMLDivElement> = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(selected as T[]);
      setSelected([]);
    },
    [onSubmit, selected, setSelected],
  );

  const _filterOption = useCallback(
    (option: FilterOptionOption<T>, inputValue: string) => {
      if (selected.some(it => it.value._id === option.data.value._id)) {
        return false;
      }
      return filterOption ? filterOption(option, inputValue) : true;
    },
    [selected, filterOption],
  );

  return (
    <Stack spacing={4} as="form" onSubmit={onSubmitHandler} w="full">
      <AsyncSelect<T, true>
        name={selectProps.name ?? 'searchMenuMulti'}
        size="md"
        displayIcon
        placeholder={`${t('action_search')}...`}
        loadOptions={loadOptions}
        isMulti
        isSearchable
        value={selected}
        filterOption={_filterOption as unknown as (option: FilterOptionOption<T>) => boolean}
        isClearable={false}
        components={{
          Option,
        }}
        /** We disable the Select "rendering" of values as we use a custom display using the {@link SearchMenuSelectedList} component. */
        controlShouldRenderValue={false}
        onChange={onChangeHandler}
        isDisabled={isAddDisabled}
        {...selectProps}
      />
      <SearchMenuSelectedList<T>
        selected={selected}
        ValueComponent={Value}
        onDelete={onDeleteHandler}
      />
      <Flex w="full" alignItems="center">
        <IconButton
          size="sm"
          onClick={onClearHandler}
          icon={<Icon as={FiTrash} />}
          aria-label={t('action_clear')}
          isDisabled={isEmpty}
          variant="ghost"
          colorScheme="red"
          type="reset"
        />
        <IconButton
          size="sm"
          ml="auto"
          icon={<Icon as={FiCheck} />}
          aria-label={t('action_save')}
          type="submit"
          isDisabled={isEmpty}
          colorScheme="green"
          isLoading={isLoading}
        />
      </Flex>
    </Stack>
  );
}
