import React from 'react';

import { CohortGroupFormList } from '@/components/cohorts';
import { environment } from '@/config/environment';
import { useGetUserCohortGroupsQuery } from '@/store/services/users';

function _EventFormCohort() {
  const { data: cohortGroupsAllowed, isLoading } = useGetUserCohortGroupsQuery();

  return (
    <CohortGroupFormList
      cohortGroupsAllowed={cohortGroupsAllowed}
      isLoading={isLoading}
      warningOnEmpty={true}
    />
  );
}

export const EventFormCohort = React.memo(_EventFormCohort);

if (environment.__DEV__) {
  EventFormCohort.displayName = 'EventFormCohort';
}
