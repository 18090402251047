import type { EventSlotInput } from '@epitech/ops-panoramix-events-types';

export const DEFAULT_SLOT_PARAMS: EventSlotInput = {
  duration: 30,
  occurence: 1,
  breakDuration: 0,
};

export const PANORAMIX_ROUTES = ['calendar', 'modules', 'rooms/timeline', 'rooms/status'] as const;
export const PANORAMIX_OPEN_ROUTES = ['calendar', 'rooms/status'] as const;
