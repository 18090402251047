import { MultiValue, SingleValue } from 'chakra-react-select';
import { ActionMeta, GroupBase, OnChangeValue } from 'chakra-react-select';
import unionBy from 'lodash/unionBy';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { IOption, IRef } from '../types';
import { AsyncSelectBase } from './Async.base';
import { IAsyncSelectProps } from './Async.types';

// TODO: We currently rerender every select
export function AsyncSelectFormWrapper<
  Option extends IOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  name,
  defaultValue,
  defaultOptions,
  onChange,
  loadOptions,
  ...otherProps
}: IAsyncSelectProps<Option, IsMulti, Group>) {
  const { control, resetField } = useFormContext();
  const {
    field: { ref: controllableRef, onChange: onControllerChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });
  const [currentOptions, setCurrentOptions] = React.useState<Option[]>(() =>
    Array.isArray(defaultOptions) ? defaultOptions : [],
  );

  /**
   * We have no way to access the current options loaded in the `select` since they are loaded asynchronously.
   * We need to create a state to store the current options loaded to be able to map the value back to the label.
   */
  const _loadOptions = async (
    inputValue: string,
    callback?: (options: Option[]) => void | Promise<Option[]>,
    limit?: number,
    offset?: number,
  ) => {
    const options = await loadOptions(inputValue, callback, limit, offset);

    setCurrentOptions(_options => unionBy(_options, options, 'label'));
    return options;
  };

  const _getOptionFromValue = useCallback(
    (value: string | IRef): Option | null =>
      value
        ? (currentOptions.find(option => {
            const optionValue = (option as unknown as IOption).value;

            if (optionValue === null || typeof optionValue === 'string') {
              return optionValue === value;
            }
            return optionValue._id === (value as IRef)._id;
          }) as Option)
        : null,
    [currentOptions],
  );

  const getOptionsFromValue = useCallback(
    (value: (string[] | string) | (IRef[] | IRef)) => {
      if (Array.isArray(value)) {
        return value.map(_getOptionFromValue) as Option[];
      }
      return _getOptionFromValue(value) as Option;
    },
    [_getOptionFromValue],
  );

  const extractValue = useCallback(
    (value?: MultiValue<Option> | SingleValue<Option>) =>
      value && 'value' in (value as unknown as IOption)
        ? (value as unknown as IOption)?.value
        : value,
    [],
  );

  const onControlledChange = useCallback(
    (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => {
      const isMulti = Array.isArray(newValue) && otherProps.isMulti;

      if (actionMeta.action === 'clear') {
        if (isMulti) {
          resetField(name, { defaultValue: [] });
        } else {
          resetField(name, { defaultValue: null });
        }
      } else {
        const controlledValue = isMulti ? newValue.map(extractValue) : extractValue(newValue);
        onControllerChange(controlledValue);
      }
      if (onChange) onChange(newValue, actionMeta);
    },
    [onControllerChange, onChange, resetField, extractValue, otherProps.isMulti, name],
  );

  return (
    <AsyncSelectBase
      hideSelectedOptions={true}
      {...inputProps}
      {...otherProps}
      defaultOptions
      cacheOptions
      selectRef={controllableRef}
      loadOptions={_loadOptions}
      onChange={onControlledChange}
      value={getOptionsFromValue(inputProps.value)}
    />
  );
}
