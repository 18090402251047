import { Icon, IconProps, StackProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiLayers } from 'react-icons/fi';

interface IEmptyListPlaceholderProps extends StackProps {
  resource: string;
  iconSize?: IconProps['w'];
}

export const EmptyListPlaceholder: React.FC<IEmptyListPlaceholderProps> = ({
  resource,
  iconSize = 10,
  ...stackProps
}) => {
  const { t } = useTranslation('components/shared');

  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      w="full"
      py={4}
      color="gray.300"
      {...stackProps}
    >
      <Icon as={FiLayers} w={iconSize} h={iconSize} />
      <Text fontStyle="italic" fontSize="sm" color="gray.400">
        {resource ? `${t('nothing')} ${resource?.toLowerCase()}.` : t('no_data')}
      </Text>
    </VStack>
  );
};
