import { IconButton, type IconButtonProps, Link, Tooltip } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SiMicrosoftteams } from 'react-icons/si';

import { environment } from '@/config/environment';

interface TeamsButtonProps extends Partial<IconButtonProps> {
  users: string[] | undefined;
  isHover?: boolean;
}

function _TeamsButton({ users, ...buttonProps }: TeamsButtonProps) {
  const { t } = useTranslation('actions');

  const teamsLink = useMemo(() => {
    if (!users) {
      return;
    }
    return `${environment.TEAMS_URI}?users=${users.join(',')}`;
  }, [users]);

  if (!users || users.length === 0 || !teamsLink) {
    return null;
  }

  return (
    <Link isExternal={true} href={teamsLink}>
      <Tooltip label={t('open_teams_channel')} closeOnClick={true}>
        <IconButton icon={<SiMicrosoftteams />} {...buttonProps} aria-label="inscription" />
      </Tooltip>
    </Link>
  );
}

export const TeamsButton = React.memo<TeamsButtonProps>(_TeamsButton);
