import { HStack, type StackProps, Text } from '@chakra-ui/react';
import React from 'react';

interface ILabelDataProps {
  label: string | React.ReactNode;
}

const _LabelData = React.forwardRef(function _LabelData(
  { label, children, ...stackProps }: ILabelDataProps & StackProps,
  ref,
) {
  return (
    <HStack spacing={4} w="full" alignItems="center" ref={ref} {...stackProps}>
      <Text color="secondFontColor">{typeof label === 'string' ? `${label}:` : label}</Text>
      {children}
    </HStack>
  );
});

export const LabelData = React.memo(_LabelData);
