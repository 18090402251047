import {
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { EVENT_TYPES } from '@epitech/ops-panoramix-events-types';
import { addDays, addMinutes, startOfDay } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiX } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { DatePicker } from '@/components/ui-elements/DatePicker';
import { SelectMemo } from '@/components/ui-elements/Select/Select';
import { getEventPrefix } from '@/lib/helpers/events.helpers';

import { EventFormCohort } from './Cohort';
import { eventTypeOptions } from './form.data';
import { IEventFormRawData, IPropsEventForm } from './form.types';
import { isFullDayEvent } from './helpers';
import { EventFormModule } from './Module';
import { EventFormRoom } from './Rooms';
import { EventFormSlots } from './slots';
import { EventFormSubmit, EventFormSubmitProps } from './Submit';

export const EventForm: React.FC<
  IPropsEventForm & Omit<EventFormSubmitProps, 'endDateOverflow' | 'onInternalSubmitAndOpen'>
> = React.memo(({ onSubmit, onClose, header, submitText, submitIcon, isLoading, eventId }) => {
  const formMethods = useFormContext<IEventFormRawData>();
  const {
    register,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = formMethods;

  const navigate = useNavigate();
  const location = useLocation();

  const eventTypeOpts = useMemo(() => eventTypeOptions(), []);
  const { t } = useTranslation('components/event-form');
  const { t: tShared } = useTranslation('components/shared');
  const { t: tForm } = useTranslation('form_errors');

  const [startDate, endDate, type, moduleRef, title, endDateOverflow, activityRef] = watch([
    'start',
    'end',
    'type',
    'moduleRef',
    'title',
    'endDateOverflow',
    'moduleRef.activityRef',
  ]);
  const [breaks] = watch(['slots.breaks']);

  const [isAllDay, setIsAllDay] = React.useState(() => isFullDayEvent(startDate, endDate));

  const onInternalSubmitAndOpen = useCallback(
    (openAfter: boolean = false) =>
      (data: IEventFormRawData) => {
        if (endDateOverflow) {
          data.end = endDateOverflow;
        }
        onSubmit(data, openAfter);
      },
    [endDateOverflow, onSubmit],
  );

  React.useEffect(() => {
    breaks?.map((elem, index) => {
      if (!elem || !elem?.start || !elem?.end) return;
      const newStart = elem.start;
      const newEnd = elem.end;
      newStart.setDate(startDate.getDate());
      newStart.setMonth(startDate.getMonth());
      newStart.setFullYear(startDate.getFullYear());
      newEnd.setDate(startDate.getDate());
      newEnd.setMonth(startDate.getMonth());
      newEnd.setFullYear(startDate.getFullYear());
      setValue(`slots.breaks.${index}.start`, newStart);
      setValue(`slots.breaks.${index}.end`, newEnd);
    });
  }, [startDate, setValue, breaks]);

  const computedPrefix = useMemo(() => {
    if (!moduleRef) {
      return null;
    }
    return getEventPrefix({ ...moduleRef, activityRef });
  }, [moduleRef, activityRef]);

  const handleAllDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newStartDate = startOfDay(startDate ? startDate : new Date());
      setValue('start', newStartDate);
      setValue('end', addDays(newStartDate, 1));
    }
    setIsAllDay(e.target.checked);
  };

  const backToEvent = useCallback(() => {
    navigate({
      pathname: `/calendar/events/${eventId}/details`,
      search: location.search,
    });
  }, [eventId, location.search, navigate]);

  return (
    <Modal
      motionPreset={isLoading ? 'none' : 'scale'}
      isOpen={true}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent padding={0} position="relative">
        <form autoComplete="off">
          <HStack justify="space-between" width="full" px={6} align="center">
            <ModalHeader fontSize="2xl" as="h1" flex={1} px={0}>
              {header}
            </ModalHeader>
            {eventId && (
              <Tooltip label={tShared('action_back_event')}>
                <IconButton
                  aria-label={tShared('action_back_event')}
                  icon={<FiArrowLeft />}
                  variant="ghost"
                  onClick={backToEvent}
                />
              </Tooltip>
            )}
            <IconButton aria-label="Close" icon={<FiX />} variant="ghost" onClick={onClose} p="0" />
          </HStack>
          <ModalBody>
            <VStack spacing={4} alignItems="flex-stat">
              <EventFormModule />
              <Stack direction={['column', 'row']}>
                <FormControl isInvalid={errors.title !== undefined} variant="white" flex={2}>
                  <FormLabel>{t('title')}</FormLabel>
                  <Input
                    placeholder={t('title')}
                    size="md"
                    variant="white"
                    {...register('title')}
                  />
                  {computedPrefix && (
                    <FormHelperText>
                      <Text as="span" fontFamily="mono" color="inherit">
                        {computedPrefix}
                      </Text>
                      &nbsp;
                      {title}
                    </FormHelperText>
                  )}
                  {errors.title && errors.title.message && (
                    <FormErrorMessage>
                      {tForm(errors.title.message, { property: t('title') })}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl flex={1}>
                  <FormLabel>{t('type')}</FormLabel>
                  <SelectMemo name="type" hasFormContext={true} options={eventTypeOpts} />
                  {errors.type && errors.type.message && (
                    <FormErrorMessage>
                      {tForm(errors.type.message, {
                        property: t('type'),
                        types: EVENT_TYPES.join(' '),
                      })}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <EventFormRoom defaultRoomsRef={getValues('roomsRef')} />
              <Stack direction={['column', 'row']}>
                <FormControl isInvalid={errors.start !== undefined}>
                  <FormLabel>{t('begin')}</FormLabel>
                  <DatePicker
                    name="start"
                    disabled={isAllDay}
                    onChange={(date: Date) => {
                      if (endDate < date) {
                        setValue('end', addMinutes(date, 5));
                      }
                    }}
                    showTimeSelect={true}
                    timeIntervals={5}
                    minDate={new Date()}
                    selectsStart
                  />
                  {errors.start && errors.start.message && (
                    <FormErrorMessage>
                      {tForm(errors.start.message, {
                        property: t('begin'),
                      })}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.end !== undefined}>
                  <FormLabel>{t('end')}</FormLabel>
                  <DatePicker
                    name="end"
                    disabled={isAllDay}
                    showTimeSelect={true}
                    timeIntervals={5}
                    minDate={startDate}
                    isInvalid={endDateOverflow !== undefined || errors.end !== undefined}
                    selectsEnd
                  />
                  {endDateOverflow && <FormHelperText>{t('date_overflowing')}</FormHelperText>}
                  {errors.end && errors.end.message && (
                    <FormErrorMessage>
                      {tForm(errors.end.message, {
                        property: t('end'),
                        relatedProperty: t('begin'),
                      })}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Checkbox
                justifyContent="center"
                size="sm"
                borderColor="gray.400"
                justifySelf="self-start"
                defaultChecked={isAllDay}
                onChange={handleAllDayChange}
              >
                {t('all_day')}
              </Checkbox>
              {(type === 'appointment' || type === 'appointment_multiple') && (
                <>
                  <Divider />
                  <EventFormSlots eventStart={startDate} eventEnd={endDate} />
                </>
              )}
              <Divider />
              <EventFormCohort />
              <Divider />
              <FormControl>
                <FormLabel>{t('description')}</FormLabel>
                <Textarea
                  placeholder={t('description')}
                  size="md"
                  variant="white"
                  resize="none"
                  {...register('description')}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter display="flex" flexDir="row" alignItems="center">
            <Checkbox {...register('visible')} size="lg" fontWeight="medium" borderColor="gray.400">
              {t('visible')}
            </Checkbox>
            <Spacer />
            <EventFormSubmit
              onInternalSubmitAndOpen={onInternalSubmitAndOpen}
              isLoading={isLoading}
              submitText={submitText}
              submitIcon={submitIcon}
              endDateOverflow={endDateOverflow !== undefined}
            />
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
});
