import { Accordion, PopoverBody, PopoverHeader, Text } from '@chakra-ui/react';
import { HistoryEntry } from '@epitech/ops-panoramix-events-types';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyListPlaceholder } from '@/components/ui-elements/Placeholders';
import { environment } from '@/config/environment';
import { sanitizeDatePayload } from '@/lib/helpers/dates.helpers';
import type { WithRawDates } from '@/lib/types/events';
import { useGetEventHistoryQuery, useGetEventQuery } from '@/store/services/events';

import { useEventDetailsContext } from '../../detailsProvider/useEventDetailsContext';
import { EventHistoryEntryItem } from './EntryItem';

// see https://redux-toolkit.js.org/rtk-query/usage/queries#selecting-data-from-a-query-result for Ref
const emptyArray: WithRawDates<HistoryEntry>[] = [];

function _EventDetailsHistory() {
  const { t } = useTranslation('components/events/history');
  const { eventId, canViewHistoryLog } = useEventDetailsContext();
  const { history: baseHistory = [] } = useGetEventQuery(eventId, {
    selectFromResult: ({ data }) => {
      return {
        history: data?.history ?? emptyArray,
      };
    },
  });
  const { data: detailedHistory = [] } = useGetEventHistoryQuery(eventId, {
    skip: !canViewHistoryLog,
  });

  const history = useMemo(() => {
    const toSanitize =
      detailedHistory.length > 0
        ? detailedHistory
        : cloneDeep(baseHistory)?.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          ) || [];
    return sanitizeDatePayload<HistoryEntry[]>(toSanitize);
  }, [detailedHistory, baseHistory]);

  return (
    <>
      <PopoverHeader textStyle="popover-header" py={4}>
        <Text fontSize="lg">{t('history_title')}</Text>
      </PopoverHeader>
      {history.length === 0 ? (
        <PopoverBody>
          <EmptyListPlaceholder resource={t('history_title').toLowerCase()} iconSize={7} />
        </PopoverBody>
      ) : (
        <PopoverBody as={Accordion} allowToggle={true} fontSize="sm" maxH={500} overflowY="auto">
          {history.map((historyEntry, i) => (
            // key is i because the list would not change (no delete/sort/etc) not common best pratice btw
            <EventHistoryEntryItem historyEntry={historyEntry} key={i} />
          ))}
        </PopoverBody>
      )}
    </>
  );
}

export const EventDetailsHistory = React.memo(_EventDetailsHistory);

if (environment.__DEV__) {
  EventDetailsHistory.displayName = 'EventDetailsHistory';
}
