import { Icon, IconButton } from '@chakra-ui/react';
import { chakraComponents, GroupBase, IndicatorsContainerProps } from 'chakra-react-select';
import React, { useCallback } from 'react';
import { FiCheck } from 'react-icons/fi';

export function SubmitSingleButton<T>({
  ...props
}: IndicatorsContainerProps<T, false, GroupBase<T>>) {
  const { selectProps } = props;

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <chakraComponents.IndicatorsContainer<T, false, GroupBase<T>>
      {...props}
      innerProps={{ onMouseDown: handleMouseDown }}
    >
      <IconButton
        ml="auto"
        borderRadius={0}
        size={selectProps.size}
        icon={<Icon as={FiCheck} />}
        aria-label="save"
        type="submit"
        isDisabled={!props.hasValue}
        colorScheme="green"
        isLoading={selectProps.isLoading}
      />
    </chakraComponents.IndicatorsContainer>
  );
}
