import {
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserPlus } from 'react-icons/fi';

import { environment } from '@/config/environment';

import { LogasContent } from './Content';

function _Logas() {
  const { t } = useTranslation('components/users');

  return (
    <Popover
      placement="bottom-end"
      isLazy={true}
      trigger="click"
      variant="noBorder"
      computePositionOnMount={true}
    >
      <PopoverTrigger>
        <IconButton
          aria-label={t('action_logas')}
          icon={<Icon as={FiUserPlus} />}
          colorScheme="blue"
          size="xs"
        />
      </PopoverTrigger>
      <PopoverContent p={4} boxShadow="lg">
        <PopoverArrow />
        <LogasContent />
      </PopoverContent>
    </Popover>
  );
}

export const Logas = React.memo(_Logas);

if (environment.__DEV__) {
  Logas.displayName = 'Logas';
}
