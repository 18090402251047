import type { RoomRef } from '@epitech/ops-panoramix-types';
import { useCallback } from 'react';

import { type ISearchRoomsRequest, useLazySearchRoomsQuery } from '@/store/services/events';

import type { IRoomOption } from './select/helpers';

export const getRoomOption = (roomRef: RoomRef): IRoomOption => ({
  label: roomRef.name,
  value: roomRef,
});

export function useRoomSearch(params?: ISearchRoomsRequest) {
  const [searchRooms] = useLazySearchRoomsQuery();

  return useCallback(
    async (
      search: string,
      callback?: (options: IRoomOption[]) => void | Promise<IRoomOption[]>,
      limit?: number,
      offset?: number,
    ) => {
      const result = await searchRooms({ ...params, search, limit, offset }, true).unwrap();
      return result.map(getRoomOption);
    },
    [searchRooms, params],
  );
}
