import {
  FocusLock,
  PopoverCloseButton,
  PopoverHeader,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetEventQuery } from '@/store/services/events';

import { EventMassForceRegister, EventMassForceRegisterProps } from '../MassForceRegister';

export type EventMasseForceRegisterActionButtonContentProps = EventMassForceRegisterProps & {
  eventId: string;
  allSlotsIdx: number[];
  isDisabled?: boolean;
  hasPortal?: boolean;
};

function _EventMasseForceRegisterActionButtonContent({
  eventId,
  allSlotsIdx,
  type,
  isDisabled,
  ...forceRegisterProps
}: EventMasseForceRegisterActionButtonContentProps) {
  const { t } = useTranslation('components/events');
  const { data: event } = useGetEventQuery(eventId);

  if (!event) {
    return null;
  }

  const title = t(`menu_mass_register_title_${type}`);

  return (
    <FocusLock restoreFocus={true}>
      <VStack spacing={4} w="full" divider={<StackDivider />}>
        <PopoverHeader textStyle="popover-header" px={0} py={0} w="full" display="flex">
          <PopoverCloseButton p={0} top={3.5} />
          <Text>{title}</Text>
        </PopoverHeader>
        <EventMassForceRegister
          type={type}
          eventId={eventId}
          allSlotsIdx={allSlotsIdx}
          {...forceRegisterProps}
        />
      </VStack>
    </FocusLock>
  );
}

export const EventMassForceRegisterActionButtonContent = React.memo(
  _EventMasseForceRegisterActionButtonContent,
);
