import { Icon, IconButton } from '@chakra-ui/react';
import type { ProjectGroupRef, UserRef } from '@epitech/ops-panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus, FiX } from 'react-icons/fi';

import { EventRegisterAsWrapper } from '@/components/events/RegisterAsWrapper';
import { ConfirmPopover } from '@/components/ui-elements/ConfirmPopover';
import { environment } from '@/config/environment';

type StudentActionButtonProps = {
  isUserSlot: boolean;
  isPast?: boolean;
  isLoading: boolean;
  isGroupMode: boolean;
  toRegister: UserRef | ProjectGroupRef;
  allowRegistration: boolean;
  onRegister: () => Promise<void>;
  onUnregister: () => Promise<void>;
};

function _StudentActionButton({
  isUserSlot,
  isPast,
  isLoading,
  allowRegistration,
  onRegister,
  toRegister,
  isGroupMode,
  onUnregister,
}: StudentActionButtonProps) {
  const { t } = useTranslation('components/events');

  if (isUserSlot) {
    return (
      <ConfirmPopover
        onConfirm={onUnregister}
        isLoading={isLoading}
        confirmLabel={t('unregister_confirm_own_slot')}
        confirmButtonLabel={t('unregister_btn_label')}
        renderTrigger={() => (
          <IconButton
            colorScheme="red"
            icon={<Icon as={FiX} />}
            aria-label={t('desinscription')}
            size="xs"
            isDisabled={isPast}
          />
        )}
      />
    );
  }
  return (
    <EventRegisterAsWrapper isGroupMode={isGroupMode} toRegister={toRegister}>
      <IconButton
        colorScheme="blue"
        icon={<Icon as={FiPlus} />}
        aria-label="inscription"
        size="xs"
        isDisabled={!allowRegistration || isPast}
        isLoading={isLoading}
        onClick={onRegister}
      />
    </EventRegisterAsWrapper>
  );
}

export const StudentActionButton = React.memo(_StudentActionButton);

if (environment.__DEV__) {
  StudentActionButton.displayName = 'StudentActionButton';
}
