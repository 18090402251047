import { Icon, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiSettings } from 'react-icons/fi';

import { MenuPortal } from '@/components/ui-elements/MenuPortal';
import { environment } from '@/config/environment';

import { CalendarMenuContent, ICalendarMenuContentProps } from './Content';

function _CalendarMenu(calendarMenuProps: ICalendarMenuContentProps) {
  const { t } = useTranslation('components/events');

  return (
    <Menu closeOnSelect={false} variant="white" isLazy={true}>
      <MenuButton as={IconButton} icon={<Icon as={FiSettings} />}>
        {t('menu')}
      </MenuButton>
      <MenuPortal>
        <MenuList maxW="400px">
          <CalendarMenuContent {...calendarMenuProps} />
        </MenuList>
      </MenuPortal>
    </Menu>
  );
}

export const CalendarMenu = React.memo(_CalendarMenu);

if (environment.__DEV__) {
  CalendarMenu.displayName = 'CalendarMenu';
}
