import { Badge, HStack, ModalFooter, Text } from '@chakra-ui/react';
import { HistoryEntry } from '@epitech/ops-panoramix-events-types';
import { formatDate } from '@fullcalendar/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';
import { sanitizeDatePayload } from '@/lib/helpers/dates.helpers';
import { getHistoryLogCreator } from '@/lib/helpers/events.helpers';
import { WithRawDates } from '@/lib/types/events';
import { useGetEventQuery } from '@/store/services/events';

import { useEventDetailsContext } from '../detailsProvider/useEventDetailsContext';

// See https://redux-toolkit.js.org/rtk-query/usage/queries#selecting-data-from-a-query-result for Ref
const emptyArray: WithRawDates<HistoryEntry>[] = [];

function _EventDetailsLogFooter() {
  const { eventId, canViewHistory } = useEventDetailsContext();
  const { history: rawHistory } = useGetEventQuery(eventId, {
    skip: !canViewHistory,
    selectFromResult: ({ data }) => ({
      history: data?.history ?? emptyArray,
    }),
  });
  const history = useMemo(() => sanitizeDatePayload<HistoryEntry[]>(rawHistory), [rawHistory]);
  const { t } = useTranslation('components/events/history');

  if (!canViewHistory || !history || !history.length) {
    return null;
  }

  const createEntry = getHistoryLogCreator(history);

  if (!createEntry) {
    return null;
  }

  const { userRef, date } = createEntry;

  return (
    <ModalFooter
      as={HStack}
      justifyContent="flex-end"
      spacing={2}
      alignItems="center"
      py={0}
      lineHeight={1}
    >
      <Text color="secondFontColor">{t('created_by')}</Text>
      <UserItem value={userRef} avatarSize="2xs" flex={0} />
      <Text color="secondFontColor">{t('action_time')}</Text>
      <Badge fontFamily="monospace" py={1} colorScheme="teal">
        {formatDate(new Date(date), {
          locale: t('language'),
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          omitCommas: true,
        })}
      </Badge>
    </ModalFooter>
  );
}

export const EventDetailsLogFooter = React.memo(_EventDetailsLogFooter);

if (environment.__DEV__) {
  _EventDetailsLogFooter.displayName = 'EventDetailsLogFooter';
}
