import { TableProps } from '@chakra-ui/react';
import React, { type PropsWithChildren, useMemo } from 'react';

import { environment } from '@/config/environment';

import { useEventDetailsContext } from '../../detailsProvider/useEventDetailsContext';
import { EventMassForceRegisterActionButton } from '../../force-register/MassForceRegister/ActionButton/Popover';
import { SlotListBase } from '../ListBase';

type SlotRegisteredListBaseProps = TableProps & {
  allSlotsIdx: number[];
};

function _SlotRegisteredListBase({
  allSlotsIdx,
  children,
  ...slotListBaseProps
}: PropsWithChildren<SlotRegisteredListBaseProps>) {
  const { canForceRegisterStaffs, canForceRegisterRooms, isGroupMode, eventId } =
    useEventDetailsContext();

  const staffActionButton = useMemo(
    () =>
      canForceRegisterStaffs ? (
        <EventMassForceRegisterActionButton
          eventId={eventId}
          type="staffs"
          allSlotsIdx={allSlotsIdx}
        />
      ) : null,
    [allSlotsIdx, canForceRegisterStaffs, eventId],
  );

  const roomActionButton = useMemo(
    () =>
      canForceRegisterRooms ? (
        <EventMassForceRegisterActionButton
          eventId={eventId}
          type="rooms"
          allSlotsIdx={allSlotsIdx}
        />
      ) : null,
    [canForceRegisterRooms, eventId, allSlotsIdx],
  );
  return (
    <SlotListBase
      {...slotListBaseProps}
      tableLayout="fixed"
      isGroupMode={isGroupMode}
      roomActionButton={roomActionButton}
      staffActionButton={staffActionButton}
    >
      {children}
    </SlotListBase>
  );
}

export const SlotRegisteredListBase = React.memo(_SlotRegisteredListBase);

if (environment.__DEV__) {
  SlotRegisteredListBase.displayName = 'SlotRegisteredListBase';
}
