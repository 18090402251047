import { IconButton } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash } from 'react-icons/fi';

import { ConfirmPopover } from '@/components/ui-elements/ConfirmPopover';
import { useDeleteEventMutation } from '@/store/services/events';

import { useEventDetailsContext } from './detailsProvider/useEventDetailsContext';
import { EventConfirmWarning } from './Warning';

interface IEventDeleteProps {
  onConfirm: () => void;
}

function _EventDelete({ onConfirm }: IEventDeleteProps): React.ReactElement {
  const { eventId, isGroupMode, title: eventTitle } = useEventDetailsContext();
  const { t } = useTranslation('components/events');
  const [deleteEvent, { isLoading, error, reset }] = useDeleteEventMutation();

  const _onSubmit = useCallback(async () => {
    await deleteEvent({ eventId }).unwrap();
    onConfirm();
  }, [deleteEvent, eventId, onConfirm]);

  const _onSubmitForce = useCallback(async () => {
    await deleteEvent({ eventId, ignoreWarnings: true }).unwrap();
    onConfirm();
  }, [deleteEvent, eventId, onConfirm]);

  const onCancelForceSubmit = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <>
      <ConfirmPopover
        onConfirm={_onSubmit}
        isLoading={isLoading}
        confirmLabel={t('confirm')}
        confirmButtonLabel={t('delete_maj')}
        resourceName={eventTitle}
        renderTrigger={() => <IconButton icon={<FiTrash />} aria-label={t('delete')} />}
      />
      <EventConfirmWarning
        confirmTitle={t('delete_confirm')}
        descriptionType="delete"
        isGroupMode={isGroupMode}
        error={error}
        onConfirm={_onSubmitForce}
        onCancel={onCancelForceSubmit}
      />
    </>
  );
}

export const EventDelete = React.memo<IEventDeleteProps>(_EventDelete);
