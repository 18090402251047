import { useAbility } from '@casl/react';

import { PANORAMIX_OPEN_ROUTES, PANORAMIX_ROUTES } from '@/config/constants';
import { AbilityContext } from '@/config/providers';

export function useRoutingPath() {
  const ability = useAbility(AbilityContext);

  if (!ability.can('create', 'event')) {
    return PANORAMIX_OPEN_ROUTES;
  }
  return PANORAMIX_ROUTES;
}
