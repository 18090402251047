import { Link, Tag, type TagProps, Tooltip } from '@chakra-ui/react';
import React, { type PropsWithChildren } from 'react';
import { FiExternalLink } from 'react-icons/fi';

interface ExternalResourceTagProps extends TagProps {
  href?: string;
  label: string;
}

function GoToExternalSourceTag({
  href,
  children,
  label,
  ...tagProps
}: PropsWithChildren<ExternalResourceTagProps>) {
  if (!href) {
    return (
      <Tag transition="transform 0.2s, background-color 0.2s" fontFamily="mono" {...tagProps}>
        {children}
      </Tag>
    );
  }

  return (
    <Link isExternal href={href} _hover={{ textDecoration: 'none' }}>
      <Tooltip label={label}>
        <Tag
          display="flex"
          gap={2}
          justifyContent="center"
          alignItems="center"
          transition="transform 0.2s, background-color 0.2s"
          fontFamily="mono"
          {...tagProps}
        >
          {children}
          <FiExternalLink />
        </Tag>
      </Tooltip>
    </Link>
  );
}

export default React.memo(GoToExternalSourceTag);
