import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFetchModuleState } from '@/store/services/modules';

import { IEventFormRawData } from './form.types';

export type EventFormSubmitProps = {
  isLoading: boolean;
  submitText: string;
  endDateOverflow: boolean;
  onInternalSubmitAndOpen: (openAfter?: boolean) => (data: IEventFormRawData) => void;
  submitIcon:
    | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
    | undefined;
};

function _EventFormSubmit({
  isLoading,
  submitText,
  submitIcon,
  endDateOverflow,
  onInternalSubmitAndOpen,
}: EventFormSubmitProps) {
  const { t } = useTranslation('components/event-form');
  const { watch, handleSubmit } = useFormContext<IEventFormRawData>();
  const moduleRef = watch('moduleRef');
  const isModuleLoading = useFetchModuleState(moduleRef?._id || '').isFetching;
  const message = t('submit_loading');
  const messageAndOpen = t('submit_and_open');

  const buttonBase = (
    <Button
      type="submit"
      colorScheme="blue"
      rightIcon={submitIcon}
      onClick={handleSubmit(onInternalSubmitAndOpen(false))}
      isLoading={isLoading || isModuleLoading}
    >
      {submitText} {endDateOverflow ? t('adjust_form') : ''}
    </Button>
  );

  const buttonAndOpenBase = (
    <Button
      type="submit"
      colorScheme="blue"
      rightIcon={submitIcon}
      onClick={handleSubmit(onInternalSubmitAndOpen(true))}
      isLoading={isLoading || isModuleLoading}
      ml={2}
    >
      {submitText} {endDateOverflow ? t('adjust_form') : ''} {messageAndOpen}
    </Button>
  );

  if (isModuleLoading) {
    return (
      <>
        <Tooltip hasArrow label={message} aria-label={message}>
          {buttonBase}
        </Tooltip>
        <Tooltip hasArrow label={message} aria-label={message}>
          {buttonAndOpenBase}
        </Tooltip>
      </>
    );
  }
  return (
    <>
      {buttonBase}
      {buttonAndOpenBase}
    </>
  );
}

export const EventFormSubmit = React.memo(_EventFormSubmit);
