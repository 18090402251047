import i18n from '@/lib/hooks/i18n';

import { EventRegistrationTypeOption, EventTypeOption } from './form.types';

export const eventTypeOptions = (): EventTypeOption[] => [
  { value: 'course', label: i18n.t('course', { ns: 'global' }) },
  { value: 'appointment', label: i18n.t('appointment', { ns: 'global' }) },
  { value: 'appointment_multiple', label: i18n.t('appointment_multiple', { ns: 'global' }) },
];

export const eventRegistrationTypeOptions = (): EventRegistrationTypeOption[] => [
  { value: 'individual', label: i18n.t('individual', { ns: 'global' }) },
  { value: 'group', label: i18n.t('group', { ns: 'global' }) },
];
