import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { HStack, Icon, IconButton, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { FieldErrors, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

import { environment } from '@/config/environment';

import { IEventFormInputBreaks } from '../slots.types';
import { SlotBreakInput } from './Input';

type EventFormSlotsBreaks = {
  eventStart: Date;
  eventEnd: Date;
  errors: FieldErrors<IEventFormInputBreaks>;
};

function _EventFormSlotsBreaks({ eventStart, eventEnd, errors }: EventFormSlotsBreaks) {
  const { control, watch, setValue, trigger } = useFormContext<IEventFormInputBreaks>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'slots.breaks',
  });
  const [breaks, start, end] = watch(['slots.breaks', 'start', 'end']);
  const { t } = useTranslation('layouts/calendar/eventForm/slots/EventFormSlots');
  const { t: tForm } = useTranslation('form_errors');

  const isAddDisabled = useMemo(() => {
    return (
      breaks &&
      eventEnd &&
      eventStart &&
      (breaks.some(b => !b.start || !b.end) ||
        breaks[breaks.length - 1]?.end?.getTime() === eventEnd.getTime())
    );
  }, [breaks, eventEnd, eventStart]);

  useEffect(() => {
    trigger('slots');
  }, [breaks, start, end, trigger]);

  const errorMessage = useMemo(() => {
    if (!errors.slots) {
      return false;
    }
    if (!errors?.slots?.breaks) {
      return errors?.slots?.message;
    }
    return errors?.slots?.breaks?.message;
  }, [errors.slots]);

  const setBreakValue = React.useCallback(
    (idx: number, value: Date) => {
      if (value > eventEnd) {
        return;
      }
      setValue(`slots.breaks.${idx}.end`, value);
    },
    [setValue, eventEnd],
  );

  const onRemove = React.useCallback(
    (idx: number) => {
      remove(idx);
    },
    [remove],
  );

  return (
    <FormControl isInvalid={errorMessage ? true : false}>
      <HStack justify="space-between" alignItems="center">
        <FormLabel fontSize="sm">{t('break')}</FormLabel>
        <IconButton
          isDisabled={isAddDisabled || !eventStart || !eventEnd}
          colorScheme="blue"
          aria-label={t('Add break group')}
          icon={<Icon as={FiPlus} />}
          type="button"
          size="xs"
          onClick={() => {
            append({});
          }}
        />
      </HStack>
      <VStack spacing={2}>
        {errorMessage && (
          <FormErrorMessage justifySelf="flex-start">
            {tForm(errorMessage, { property: t('breaks') })}
          </FormErrorMessage>
        )}
        {fields.map((it, idx) => {
          return (
            <SlotBreakInput
              key={it.id}
              idx={idx}
              remove={onRemove}
              setBreakValue={setBreakValue}
              startDate={breaks ? breaks[idx]?.start : null}
              endDate={breaks ? breaks[idx]?.end : null}
              minHour={eventStart}
              maxHour={eventEnd}
            />
          );
        })}
      </VStack>
    </FormControl>
  );
}

export const EventFormSlotsBreaks = React.memo(_EventFormSlotsBreaks);

if (environment.__DEV__) {
  EventFormSlotsBreaks.displayName = 'EventFormSlotsBreaks';
}
