import {
  Button,
  ButtonGroup,
  Collapse,
  Fade,
  FormControl,
  FormLabel,
  HStack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import {
  getRegisterFromMetadataValidator,
  validateCgHashes,
} from '@epitech/ops-panoramix-events-types';
import { CohortGroup } from '@epitech/ops-panoramix-types';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiEye, FiEyeOff } from 'react-icons/fi';

import { AsyncSelect } from '@/components/ui-elements/Select/Async/Async';
import { environment } from '@/config/environment';

import { CohortGroupFormList } from '../../cohorts';
import {
  getModuleOption,
  IModuleOption,
  ModuleOption,
  ModuleValue,
  useModuleSearch,
} from '../../modules';
import { type IUserSearchFormPreviewProps, UserSearchFormPreview } from './Preview';
import { IUserSearchForm } from './types';

interface IUserSearchFormProps extends IUserSearchForm {
  onSubmit: (metadata: IUserSearchForm) => void;
  defaultPreviewOpen?: boolean;
  filterUsersInPreview?: IUserSearchFormPreviewProps['filterUsers'];
  isSubmitLoading: boolean;
  submitBtnLabel: string;
}

function _UserSearchForm({
  moduleRef,
  cohortGroups = [],
  onSubmit,
  isSubmitLoading,
  submitBtnLabel,
  defaultPreviewOpen = false,
  filterUsersInPreview,
}: IUserSearchFormProps) {
  const validateCurrentCgHashes = useCallback(
    (currentCg: CohortGroup[]) =>
      validateCgHashes(
        cohortGroups.map(cg => CohortGroup.getCohortGroupHash(cg)),
        currentCg,
      ),
    [cohortGroups],
  );
  const searchFormResolver = useMemo(
    () => getRegisterFromMetadataValidator(cohortGroups),
    [cohortGroups],
  );
  const searchModules = useModuleSearch();
  const { t } = useTranslation('components/users');
  const formMethods = useForm<IUserSearchForm>({
    defaultValues: {
      moduleRef,
      cohortGroups,
    },
    shouldUnregister: true,
    mode: 'onSubmit',
    resolver: classValidatorResolver(searchFormResolver),
  });
  const [validCg, setValidCg] = useBoolean(() =>
    validateCurrentCgHashes(formMethods.getValues().cohortGroups),
  );
  const [showPreview, setPreview] = useBoolean(defaultPreviewOpen && validCg);
  const [nbOfUsers, setNbOfUsers] = useState<number | null>(null);
  const cohortLabel = useMemo(
    () => (
      <FormLabel textStyle="muted-label" fontSize="sm">
        {t('cohorts')}
      </FormLabel>
    ),
    [t],
  );

  const watch = formMethods.watch;
  const formValues = watch();

  /** This could be achieved by triggering the validation `onChange` mode, however `ArrayFields` does not seem to trigger it. This is a workaround. */
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.includes('cohortGroups')) {
        if (!validateCurrentCgHashes((value as IUserSearchForm).cohortGroups)) {
          setValidCg.off();
          setPreview.off();
        } else {
          setValidCg.on();
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setPreview, setValidCg, validateCurrentCgHashes]);

  return (
    <FormProvider {...formMethods}>
      <VStack
        align="stretch"
        width="lg"
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        spacing={4}
        fontSize="sm"
      >
        <FormControl isInvalid={formMethods.formState.errors.moduleRef !== undefined}>
          <FormLabel textStyle="muted-label" fontSize="sm">
            {t('module')}
          </FormLabel>
          <AsyncSelect<IModuleOption>
            isReadOnly={!!moduleRef}
            name="moduleRef"
            size="sm"
            hasFormContext={true}
            loadOptions={searchModules}
            htmlDecode={true}
            isDisabled={!!moduleRef}
            defaultOptions={moduleRef ? [getModuleOption(moduleRef)] : true}
            components={{ Option: ModuleOption, SingleValue: ModuleValue }}
            infiniteScroll={true}
          />
        </FormControl>
        <CohortGroupFormList cohortGroupsAllowed={cohortGroups} label={cohortLabel} />
        <HStack w="full">
          <Fade in={nbOfUsers !== null && nbOfUsers > 0 && showPreview} unmountOnExit={true}>
            <Text fontFamily="mono" willChange="transform">
              {nbOfUsers} {t('students')}
            </Text>
          </Fade>
          <ButtonGroup size="sm" justifyContent="flex-end" ml="auto" isDisabled={!validCg}>
            <Button
              type="button"
              leftIcon={!showPreview ? <FiEye /> : <FiEyeOff />}
              onClick={setPreview.toggle}
            >
              {t('preview')}
            </Button>
            <Button
              isDisabled={nbOfUsers === 0 || !validCg}
              type="submit"
              colorScheme="green"
              textTransform="capitalize"
              leftIcon={<FiCheck />}
              isLoading={isSubmitLoading}
            >
              {submitBtnLabel}
            </Button>
          </ButtonGroup>
        </HStack>
        <Collapse
          in={showPreview && validCg}
          animateOpacity={true}
          transition={{ enter: { restDelta: 0.6 }, exit: { restDelta: 0.6 } }}
        >
          <UserSearchFormPreview
            filterUsers={filterUsersInPreview}
            formValues={formValues}
            setNbOfUsers={setNbOfUsers}
            willChange="transform"
          />
        </Collapse>
      </VStack>
    </FormProvider>
  );
}

export const UserSearchForm = React.memo(_UserSearchForm);

if (environment.__DEV__) {
  UserSearchForm.displayName = 'UserSearchForm';
}
