import {
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { FiX } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

function EventFormSkeleton() {
  const navigate = useNavigate();
  const location = useLocation();

  const onClose = useCallback(() => {
    navigate({ pathname: '/calendar', search: location.search });
  }, [location.search, navigate]);

  return (
    <Modal
      motionPreset="none"
      isOpen={true}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent position="relative" px={2} py={2}>
        <HStack justify="space-between" width="full" px={4} align="center">
          <ModalHeader fontSize="2xl" as="h1" flex={1} px={0}>
            <Skeleton h="1.8em" w="11em" />
          </ModalHeader>
          <IconButton aria-label="Close" icon={<FiX />} variant="ghost" onClick={onClose} />
        </HStack>
        <ModalBody as={VStack} gap={6} minH={16} px={4} alignItems="start">
          {/* Module */}
          <VStack w="full" alignItems="start">
            <Skeleton h="1.5em" w="7em" />
            <Skeleton h="2.2em" w="full" />
          </VStack>
          {/* Activity */}
          <VStack w="full" alignItems="start">
            <Skeleton h="1.5em" w="7em" />
            <Skeleton h="2.2em" w="full" />
          </VStack>
          {/* Type inscription */}
          <HStack w="full">
            <VStack w="35%" alignItems="start">
              <Skeleton h="1.5em" w="7em" />
              <Skeleton h="2.2em" w="full" />
            </VStack>
            <VStack w="65%" alignItems="start">
              <Skeleton h="1.5em" w="7em" />
              <Skeleton h="2.2em" w="full" />
            </VStack>
          </HStack>
          {/* Title + Type */}
          <HStack w="full">
            <VStack w="60%" alignItems="start">
              <Skeleton h="1.5em" w="7em" />
              <Skeleton h="2.2em" w="full" />
            </VStack>
            <VStack w="40%" alignItems="start">
              <Skeleton h="1.5em" w="7em" />
              <Skeleton h="2.2em" w="full" />
            </VStack>
          </HStack>
          {/* Room */}
          <VStack w="full" alignItems="start">
            <Skeleton h="1.5em" w="7em" />
            <Skeleton h="2.2em" w="full" />
          </VStack>
          {/* Start + End */}
          <HStack w="full">
            <VStack w="50%" alignItems="start">
              <Skeleton h="1.5em" w="7em" />
              <Skeleton h="2.2em" w="full" />
            </VStack>
            <VStack w="50%" alignItems="start">
              <Skeleton h="1.5em" w="7em" />
              <Skeleton h="2.2em" w="full" />
            </VStack>
          </HStack>
          {/* All Day */}
          <VStack w="full" alignItems="center">
            <Skeleton h="1.5em" w="7em" />
          </VStack>
          {/* Slots */}
          <VStack w="full" alignItems="start">
            <Skeleton h="1.7em" w="10em" />
            <HStack w="full">
              <VStack w="25%" alignItems="start">
                <Skeleton h="1.5em" w="7em" />
                <Skeleton h="2.2em" w="full" />
              </VStack>
              <VStack w="25%" alignItems="start">
                <Skeleton h="1.5em" w="7em" />
                <Skeleton h="2.2em" w="full" />
              </VStack>
              <VStack w="25%" alignItems="start">
                <Skeleton h="1.5em" w="7em" />
                <Skeleton h="2.2em" w="full" />
              </VStack>
              <VStack w="25%" alignItems="start">
                <Skeleton h="1.5em" w="7em" />
                <Skeleton h="2.2em" w="full" />
              </VStack>
            </HStack>
          </VStack>
          {/* Cohorts */}
          <VStack w="full" alignItems="start">
            <Skeleton h="1.7em" w="10em" />
            <HStack w="full">
              <VStack w="30%" alignItems="start">
                <Skeleton h="1.5em" w="7em" />
                <Skeleton h="2.2em" w="full" />
              </VStack>
              <VStack w="30%" alignItems="start">
                <Skeleton h="1.5em" w="7em" />
                <Skeleton h="2.2em" w="full" />
              </VStack>
              <VStack w="30%" alignItems="start">
                <Skeleton h="1.5em" w="7em" />
                <Skeleton h="2.2em" w="full" />
              </VStack>
            </HStack>
          </VStack>
          {/* Description */}
          <VStack w="full" alignItems="start">
            <Skeleton h="1.5em" w="7em" />
            <Skeleton h="5em" w="full" />
          </VStack>
          {/* Visible + Submit */}
          <HStack w="full">
            <Skeleton h="2.2em" w="7em" />
            <Skeleton ml="auto" h="2.2em" w="7em" />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EventFormSkeleton;
