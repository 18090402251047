import { StoredEventEntry } from '@epitech/ops-panoramix-events-types';
import { PartialBy } from '@epitech/ops-panoramix-types';
import React from 'react';

import {
  ForceRegisterAppointmentMultipleUsers,
  ForceRegisterAppointmentMultipleUsersProps,
} from '@/components/events/force-register/users/appointments/Multiple';
import {
  ForceRegisterAppointmentSingleUser,
  ForceRegisterAppointmentSingleUserProps,
} from '@/components/events/force-register/users/appointments/Single';
import {
  ForceRegisterCourseUsers,
  ForceRegisterCourseUsersProps,
} from '@/components/events/force-register/users/Course';
import { environment } from '@/config/environment';

interface ForceRegisterUsersProps
  extends PartialBy<
    Omit<
      ForceRegisterAppointmentSingleUserProps &
        ForceRegisterCourseUsersProps &
        ForceRegisterAppointmentMultipleUsersProps,
      'event'
    >,
    'slotIdx'
  > {
  event: StoredEventEntry;
}

function _ForceRegisterUsers(props: ForceRegisterUsersProps) {
  const { event } = props;

  switch (event.type) {
    case 'course':
      return <ForceRegisterCourseUsers {...props} event={event} />;
    case 'appointment':
      if (props.slotIdx === undefined || isNaN(props.slotIdx)) {
        throw new Error('Slot index is required for appointment event');
      }
      return (
        <ForceRegisterAppointmentSingleUser {...props} slotIdx={props.slotIdx} event={event} />
      );
    case 'appointment_multiple':
      if (props.slotIdx === undefined || isNaN(props.slotIdx)) {
        throw new Error('Slot index is required for multiple event');
      }
      return (
        <ForceRegisterAppointmentMultipleUsers {...props} slotIdx={props.slotIdx} event={event} />
      );
    default:
      return null;
  }
}

export const ForceRegisterUsers = React.memo(_ForceRegisterUsers);

if (environment.__DEV__) {
  ForceRegisterUsers.displayName = 'ForceRegisterUsers';
}
