import type { EventSample } from '@epitech/ops-panoramix-events-types';
import type { RoomRef } from '@epitech/ops-panoramix-types';

export type RoomConflict = {
  selectedRooms: RoomRef[];
  eventsConflicting: EventSample[];
};

export function getRoomsConflictingFromEvents(
  eventsConflicting?: EventSample[],
  roomsRefSelected?: RoomRef[],
) {
  if (!eventsConflicting || !roomsRefSelected) return [];

  return roomsRefSelected.filter(room =>
    eventsConflicting.some(
      event => event.roomsRef?.some((roomRef: RoomRef) => roomRef._id === room._id),
    ),
  );
}
