import { Portal, useMenuContext } from '@chakra-ui/react';
import React from 'react';

import { environment } from '@/config/environment';

function _MenuPortal({ children }: React.PropsWithChildren) {
  const { isLazy, isOpen } = useMenuContext();
  return isLazy && !isOpen ? null : <Portal>{children}</Portal>;
}

export const MenuPortal = React.memo(_MenuPortal);

if (environment.__DEV__) {
  MenuPortal.displayName = 'MenuPortal';
}
