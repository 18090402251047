import { Button, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import type { StoredEventEntry } from '@epitech/ops-panoramix-events-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getRoomOption, type IRoomOption, RoomOption, RoomValue } from '@/components/rooms/select';
import { SearchMenu } from '@/components/ui-elements/SearchMenu/Single';
import { environment } from '@/config/environment';
import {
  useMassForceRegisterRoomsToEventMutation,
  useMassUnregisterRoomToSlotMutation,
} from '@/store/services/events';

type MassForceRegisterRoomsProps = {
  event: StoredEventEntry;
  allSlotsIdx: number[];
  onSubmit: () => void;
  onClearRooms?: () => void;
};

function ListSearchMenuWrapper({
  children,
  allSlotsIdx,
  onClearRooms,
}: { children: React.ReactNode } & Omit<
  MassForceRegisterRoomsProps,
  'onSubmit' | 'event'
>): JSX.Element {
  const { t } = useTranslation('components/events');

  return allSlotsIdx.length ? (
    <Tabs size="md" width="full" variant="line" isFitted>
      {/** We have to adjust for the Popover spacing */}
      <TabList mt={-4}>
        <Tab>{t('add')}</Tab>
        <Tab>{t('clear')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{children}</TabPanel>
        <TabPanel display={'flex'} justifyContent={'center'}>
          <Button onClick={onClearRooms}>{t('clear_room_registrations')}</Button>
        </TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>{children}</>
  );
}

const _MassForceRegisterRooms = React.forwardRef(function _MassForceRegisterRooms(
  { event, onSubmit, allSlotsIdx }: MassForceRegisterRoomsProps,
  ref,
) {
  const [massForceRegisterRoomToEvent, { isLoading: isRegisterLoading }] =
    useMassForceRegisterRoomsToEventMutation();
  const [massForceUnregisterRoomToSlot] = useMassUnregisterRoomToSlotMutation();
  const { t } = useTranslation('components/events');
  const toast = useToast();

  const onRoomSearch = useCallback(async () => {
    const result = event.roomsRef;
    return result.map(getRoomOption);
  }, [event.roomsRef]);

  const onSubmitHandler = useCallback(
    async (selectedOptions: IRoomOption['value']) => {
      if (selectedOptions) {
        await massForceRegisterRoomToEvent({
          eventId: event._id!,
          roomsToRegister: [selectedOptions],
          slotIdx: allSlotsIdx,
        }).unwrap();
        toast({
          description: t('mass_room_register_success'),
          status: 'success',
          position: 'top',
        });
        onSubmit();
      }
    },
    [onSubmit, event._id, allSlotsIdx, massForceRegisterRoomToEvent, t, toast],
  );

  const onClearRooms = useCallback(async () => {
    await massForceUnregisterRoomToSlot({
      eventId: event._id,
      slotIdx: allSlotsIdx,
    }).unwrap();
    toast({
      description: t('mass_room_unregister_success'),
      status: 'success',
      position: 'top',
    });
    onSubmit();
  }, [event._id, allSlotsIdx, massForceUnregisterRoomToSlot, onSubmit, t, toast]);

  const onNoOptions = useCallback(() => t('empty_room-search'), [t]);

  return (
    <ListSearchMenuWrapper allSlotsIdx={allSlotsIdx} onClearRooms={onClearRooms}>
      <SearchMenu<IRoomOption>
        name="massForceRegisterRooms"
        loadOptions={onRoomSearch}
        onSubmit={onSubmitHandler}
        components={{
          Option: RoomOption,
          SingleValue: RoomValue,
        }}
        isLoading={isRegisterLoading}
        noOptionsMessage={onNoOptions}
      />
    </ListSearchMenuWrapper>
  );
});

export const MassForceRegisterRooms = React.memo(_MassForceRegisterRooms);

if (environment.__DEV__) {
  MassForceRegisterRooms.displayName = 'MassForceRegisterRooms';
}
