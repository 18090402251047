import {
  Divider,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { FiX } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

function EventDetailsSkeleton() {
  const navigate = useNavigate();
  const location = useLocation();

  const onClose = useCallback(() => {
    navigate({ pathname: '/calendar', search: location.search });
  }, [location.search, navigate]);

  return (
    <Modal
      motionPreset="none"
      isOpen={true}
      onClose={onClose}
      variant="wide"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent position="relative" py={4} gap={4}>
        <HStack pr={4} w="full" justifyContent="space-between">
          <ModalHeader
            fontSize="2xl"
            as="h1"
            gap={4}
            py={0}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Skeleton h="1.5em" w="10em" />
            <Skeleton h=".7em" w="5em" />
          </ModalHeader>
          <IconButton aria-label="Close" icon={<FiX />} variant="ghost" onClick={onClose} />
        </HStack>
        <ModalBody as={VStack} px={4} gap={6} minH={16} alignItems="start">
          <VStack alignItems="start">
            <HStack>
              <Skeleton h="1.8em" w="25em" />
              <Skeleton h="1.8em" w="20em" />
            </HStack>
            <Skeleton h="1.5em" w="20em" />
            <Skeleton h="1.5em" w="17em" />
            <Skeleton h="1.5em" w="17em" />
          </VStack>
          <Divider />
          <Skeleton h="15em" w="full" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EventDetailsSkeleton;
