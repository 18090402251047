import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuOptionGroupProps,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSettings } from 'react-icons/fi';

import { MenuPortal } from '@/components/ui-elements/MenuPortal';

import { RoomStatusViewType } from './RoomStatusContainer';

interface RoomStatusMenuProps {
  onViewChange: (view: RoomStatusViewType) => void;
  view: RoomStatusViewType;
}

function _RoomStatusMenu({ onViewChange, view }: RoomStatusMenuProps) {
  const { t } = useTranslation('components/events');
  const [selectedView, setSelectedView] = useState<RoomStatusViewType>(view);

  const onChangeView = (newView: string) => {
    setSelectedView(newView as RoomStatusViewType);
    onViewChange(newView as RoomStatusViewType);
  };

  return (
    <Menu closeOnSelect={false} variant="white" isLazy={true}>
      <MenuButton as={IconButton} icon={<Icon as={FiSettings} />}></MenuButton>
      <MenuPortal>
        <MenuList maxW="400px">
          <MenuOptionGroup
            defaultValue={selectedView}
            title={t('view')}
            type="radio"
            onChange={onChangeView as MenuOptionGroupProps['onChange']}
          >
            <MenuItemOption value="grid">{t('grid')}</MenuItemOption>
            <MenuItemOption value="list">{t('list')}</MenuItemOption>
            <MenuItemOption value="tv">{t('tv')}</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </MenuPortal>
    </Menu>
  );
}

export const RoomStatusMenu = React.memo<RoomStatusMenuProps>(_RoomStatusMenu);
