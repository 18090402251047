import { TableContainerProps } from '@chakra-ui/react';
import { EventSlotEntry, EventSlotMultipleEntry } from '@epitech/ops-panoramix-events-types';
import React from 'react';

import { environment } from '@/config/environment';

import { SlotListBase } from '../ListBase';
import { SlotPreviewItem } from './PreviewItem';

type SlotPreviewListProps = {
  slots: EventSlotEntry[] | EventSlotMultipleEntry[];
  isGroupMode: boolean;
};

function _SlotPreviewList({
  slots = [],
  isGroupMode = false,
  ...containerProps
}: SlotPreviewListProps & TableContainerProps) {
  if (!slots.length) {
    return null;
  }

  return (
    <SlotListBase isGroupMode={isGroupMode} {...containerProps}>
      {slots.map((slot, i) => (
        <SlotPreviewItem key={i} slot={slot} />
      ))}
    </SlotListBase>
  );
}

export const SlotPreviewList = React.memo(_SlotPreviewList);

if (environment.__DEV__) {
  SlotPreviewList.displayName = 'SlotPreviewList';
}
