import {
  FocusLock,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { environment } from '@/config/environment';
import { onLogasChange } from '@/lib/helpers/logas.helpers';
import { useLazyGetLogasTokenQuery } from '@/store/services/users';

import { FullScreenOverlay } from '../../ui-elements/ScreenOverlay';
import { SearchMenu } from '../../ui-elements/SearchMenu/Single';
import { type IUserOption, UserOption, UserValue, useUserSearch } from '../select';

function _LogasContent() {
  const { t } = useTranslation('components/users');
  const searchUsers = useUserSearch({ logas: true });
  const [logas, { isLoading }] = useLazyGetLogasTokenQuery();

  const dispatch = useDispatch();

  const onSubmit = async (user: IUserOption['value']) => {
    onLogasChange(dispatch);
    logas(user._id);
  };

  if (isLoading) {
    return <FullScreenOverlay />;
  }

  return (
    <FocusLock restoreFocus={true}>
      <VStack spacing={0} w="full" align="stretch">
        <PopoverHeader textStyle="popover-header" px={0} pt={0} pb={4} w="full" display="flex">
          <PopoverCloseButton p={0} top={3.5} />
          <Text>{t('title_logas')}</Text>
        </PopoverHeader>
        <PopoverBody>
          <SearchMenu<IUserOption>
            name="logasUser"
            loadOptions={searchUsers}
            components={{ Option: UserOption, SingleValue: UserValue }}
            onSubmit={onSubmit}
            infiniteScroll={true}
          />
        </PopoverBody>
      </VStack>
    </FocusLock>
  );
}

export const LogasContent = React.memo(_LogasContent);

if (environment.__DEV__) {
  LogasContent.displayName = 'LogasContent';
}
