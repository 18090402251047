import { HStack, Icon, IconButton } from '@chakra-ui/react';
import { EventEntry } from '@epitech/ops-panoramix-events-types';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiTrash } from 'react-icons/fi';

import { Select, SelectMemo } from '@/components/ui-elements/Select/Select';
import { environment } from '@/config/environment';

const SELECT_SIZE: React.ComponentProps<typeof Select>['size'] = 'sm';

import { CohortGroup, CohortRef } from '@epitech/ops-panoramix-types';
import { FieldError, Merge, UseFieldArrayRemove } from 'react-hook-form';

import { IOptionGen } from '@/components/ui-elements/Select/types';

interface ICohortFormElementProps {
  indexInCG: number;
  cohortGroupMatcher: (
    cohortType: keyof CohortGroup,
    cohortOptions: IOptionGen<CohortRef>[],
    cohortGroupToMatch: Partial<CohortGroup>,
  ) => IOptionGen<CohortRef>[];
  curriculumOptions: IOptionGen<CohortRef>[];
  cityOptions: IOptionGen<CohortRef>[];
  promotionOptions: IOptionGen<CohortRef>[];
  remove: UseFieldArrayRemove;
  errorCohorts?: Merge<FieldError, CohortGroup>;
}

function _CohortGroupFormElement({
  indexInCG,
  cohortGroupMatcher,
  cityOptions,
  curriculumOptions,
  promotionOptions,
  remove,
  errorCohorts,
}: ICohortFormElementProps) {
  const { t } = useTranslation('components/event-form');

  const { watch, setValue } = useFormContext<EventEntry>();

  const curriculum = watch(`cohortGroups.${indexInCG}.curriculum`);
  const promotion = watch(`cohortGroups.${indexInCG}.promotion`);
  const city = watch(`cohortGroups.${indexInCG}.city`);

  const cityOptionsAllowed = useMemo(
    () => cohortGroupMatcher('city', cityOptions, { curriculum, promotion }),
    [cohortGroupMatcher, cityOptions, curriculum, promotion],
  );
  
  const curriculumOptionsAllowed = useMemo(
    () =>
      cohortGroupMatcher('curriculum', curriculumOptions, {
        city,
        promotion,
      }),
      [cohortGroupMatcher, curriculumOptions, city, promotion],
  );
  
  const promotionOptionsAllowed = useMemo(
    () =>
      cohortGroupMatcher('promotion', promotionOptions, {
        city,
        curriculum,
      }),
    [cohortGroupMatcher, promotionOptions, city, curriculum],
  );

  useEffect(() => {
    if (cityOptionsAllowed.length === 1 && cityOptionsAllowed[0].value._id !== city?._id) {
      setValue(`cohortGroups.${indexInCG}.city`, cityOptionsAllowed[0].value);
    }
  }, [cityOptionsAllowed, setValue, indexInCG, city]);

  useEffect(() => {
    if (curriculumOptionsAllowed.length === 1 && curriculumOptionsAllowed[0].value._id !== curriculum?._id) {
      setValue(`cohortGroups.${indexInCG}.curriculum`, curriculumOptionsAllowed[0].value);
    }
  }, [curriculumOptionsAllowed, setValue, indexInCG, curriculum]);

  useEffect(() => {
    if (promotionOptionsAllowed.length === 1 && promotionOptionsAllowed[0].value._id !== promotion?._id) {
      setValue(`cohortGroups.${indexInCG}.promotion`, promotionOptionsAllowed[0].value);
    }
  }, [promotionOptionsAllowed, setValue, indexInCG, promotion]);

  return (
    <HStack
      w="full"
      sx={{
        '& > div': {
          width: 'full',
        },
      }}
    >
      <SelectMemo
        placeholder={t('curriculum')}
        isInvalid={errorCohorts !== undefined}
        name={`cohortGroups.${indexInCG}.curriculum`}
        inputId={`curriculum-${indexInCG}`}
        hasFormContext={true}
        options={curriculumOptionsAllowed}
        displayIcon={false}
        isClearable={true}
        size={SELECT_SIZE}
      />
      <SelectMemo
        placeholder={t('promotion')}
        isInvalid={errorCohorts !== undefined}
        name={`cohortGroups.${indexInCG}.promotion`}
        inputId={`promotion-${indexInCG}`}
        hasFormContext={true}
        options={promotionOptionsAllowed}
        displayIcon={false}
        isClearable={true}
        size={SELECT_SIZE}
      />
      <SelectMemo
        placeholder={t('city')}
        isInvalid={errorCohorts !== undefined}
        name={`cohortGroups.${indexInCG}.city`}
        inputId={`city-${indexInCG}`}
        hasFormContext={true}
        options={cityOptionsAllowed}
        displayIcon={false}
        isClearable={true}
        size={SELECT_SIZE}
      />
      <IconButton
        colorScheme="red"
        aria-label="Delete cohorts group"
        icon={<Icon as={FiTrash} />}
        type="button"
        size="xs"
        onClick={() => remove(indexInCG)}
        variant="ghost"
      />
    </HStack>
  );
}

export const CohortGroupFormElement = React.memo(_CohortGroupFormElement);

if (environment.__DEV__) {
  CohortGroupFormElement.displayName = 'CohortGroupFormElement';
}
