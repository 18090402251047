import { Portal, usePopoverContext } from '@chakra-ui/react';
import React from 'react';

function _PopoverPortal({ children }: { children: React.ReactNode }) {
  const { isOpen } = usePopoverContext();

  return !isOpen ? null : <Portal>{children}</Portal>;
}

export const PopoverPortal = React.memo(_PopoverPortal);
