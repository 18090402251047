import { VStack } from '@chakra-ui/react';
import { EventSample } from '@epitech/ops-panoramix-events-types';
import type { RoomRef } from '@epitech/ops-panoramix-types';
import React, { useCallback } from 'react';

import { EventRoomsWarningItem } from './Item';

interface IEventRoomsWarningListProps {
  events: EventSample[];
  conflictingRooms: RoomRef[];
}

function _EventRoomsWarningList({ events, conflictingRooms }: IEventRoomsWarningListProps) {
  const getConflictingRooms = useCallback(
    (eventRoomsRef: RoomRef[]) => {
      return conflictingRooms.filter(room => {
        return eventRoomsRef.some(eventRoom => eventRoom._id === room._id);
      });
    },
    [conflictingRooms],
  );

  return (
    <VStack gap={2}>
      {events.map(event => (
        <EventRoomsWarningItem
          key={event._id}
          event={event}
          getConflictingRooms={getConflictingRooms}
        />
      ))}
    </VStack>
  );
}

export const EventRoomsWarningList = React.memo(_EventRoomsWarningList);
