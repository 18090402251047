import {
  Container,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import he from 'he';
import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { EventDetailsHeader } from '@/components/events/details/Header';
import { EventDetailsLogFooter } from '@/components/events/details/LogFooter';
import { EventDetailsMetadata } from '@/components/events/details/Metadata';
import { EventDetailsProvider } from '@/components/events/detailsProvider/Provider';
import { EventRegisteredList } from '@/components/events/registered/List';
import { SlotRegisteredList } from '@/components/events/slots/RegisteredList';
import { environment } from '@/config/environment';
import { useGetEventSanitizedQuery } from '@/lib/hooks/useGetEventSanitizedQuery';

import EventDetailsSkeleton from './Skeleton';

function _EventDetails() {
  /** Base and extracted values */
  const navigate = useNavigate();
  const location = useLocation();
  const { id: eventId } = useParams();

  /**
   By default, a `Modal` in chakra will focus the first tabbable element, this is to override this behavior using `initialFocusRef`
   while still keeping accessibility benefits
   */
  const initialFocusRef = useRef(null);

  const { data: event, isLoading } = useGetEventSanitizedQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  /** Sanitized values */

  /** Callbacks */
  const onClose = useCallback(() => {
    navigate({ pathname: '/calendar', search: location.search });
  }, [navigate, location.search]);

  const registeredContent = useMemo(() => {
    if (!event) {
      return null;
    }
    switch (event.type) {
      case 'appointment':
      case 'appointment_multiple':
        return <SlotRegisteredList />;
      case 'course':
        return (
          <EventRegisteredList
            registered={
              event.registrationType === 'group' ? event.registeredGroups : event.registeredUsers
            }
            registeredStaffs={event.registeredStaffs}
          />
        );
      default:
        return null;
    }
  }, [event]);

  if (isLoading) {
    return <EventDetailsSkeleton />;
  }

  if (!event) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      variant="wide"
      initialFocusRef={initialFocusRef}
      closeOnOverlayClick={false}
      motionPreset={isLoading ? 'none' : 'scale'}
    >
      <ModalOverlay />
      <ModalContent position="relative" ref={initialFocusRef} py={4} gap={4}>
        <EventDetailsProvider event={event}>
          <EventDetailsHeader />
          <ModalBody as={VStack} gap={6} minH={16} px={4}>
            <EventDetailsMetadata />
            <Divider />
            {event.description && (
              <Container maxW="full" maxH={300} overflowY="auto" px={6} whiteSpace="pre-wrap">
                {he.decode(event.description)}
              </Container>
            )}
            {registeredContent}
          </ModalBody>
          <EventDetailsLogFooter />
        </EventDetailsProvider>
      </ModalContent>
    </Modal>
  );
}

const EventDetails = React.memo(_EventDetails);

if (environment.__DEV__) {
  EventDetails.displayName = 'EventDetails';
}

export default EventDetails;
