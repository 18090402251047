import {
  Icon,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserPlus } from 'react-icons/fi';

import { PopoverPortal } from '@/components/ui-elements/PopoverPortal';

import {
  EventMasseForceRegisterActionButtonContentProps,
  EventMassForceRegisterActionButtonContent,
} from './Content';

interface EventMasseForceRegisterActionButtonProps
  extends EventMasseForceRegisterActionButtonContentProps {
  hasPortal?: boolean;
  buttonProps?: Partial<IconButtonProps>;
}

function _EventMasseForceRegisterActionButton({
  isDisabled,
  hasPortal = true,
  buttonProps = {},
  ...contentProps
}: EventMasseForceRegisterActionButtonProps) {
  const { t } = useTranslation('components/events');
  const Wrapper = hasPortal ? PopoverPortal : React.Fragment;

  const stopPropagation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return (
    <Popover
      closeOnBlur={true}
      placement="bottom-start"
      variant="noBorder"
      isLazy={true}
      lazyBehavior="unmount"
      boundary="scrollParent"
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={FiUserPlus} />}
          colorScheme="blue"
          size="xs"
          onClick={stopPropagation}
          isDisabled={isDisabled}
          aria-label={t('action_force-register')}
          {...buttonProps}
        />
      </PopoverTrigger>
      <Wrapper>
        <PopoverContent p={4} boxShadow="lg" onClick={stopPropagation}>
          <PopoverArrow />
          <EventMassForceRegisterActionButtonContent {...contentProps} isDisabled={isDisabled} />
        </PopoverContent>
      </Wrapper>
    </Popover>
  );
}

export const EventMassForceRegisterActionButton = React.memo(_EventMasseForceRegisterActionButton);
